import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BiSearch, BiDotsHorizontalRounded } from "react-icons/bi"
import { BsFillChatDotsFill, BsLink45Deg } from "react-icons/bs"
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import Select from 'react-select'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { HiDotsVertical } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchDataRedux } from "../../../../Redux/Actions/CommonActions";
import CreateConvModal from "./CreateConvModal";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../../CommonComponents/TitleBar";
import SingleConv from "./SingleConv";

const ChatNew = () => {

    const dispatch = useDispatch()
    const staffList = useSelector(state => state.staff.list)
    const [staffs, setstaffs] = useState([])
    const [conv, setConv] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [filterObj, setFilterObj] = useState({
        qs: "",
        qp: "",
        qe: "",
        typeS: "",
        typeP: "",
        typeE: ""
    })


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchConv = () => {
        let data = {}
        dispatch(fetchData("fetch-conversation-all", data, setConv, loader, setLoader))
    }

    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }


    const staffConv = conv.filter((curElem) => {
        return curElem.type === "single"
    })
    const projConv = conv.filter((curElem) => {
        return curElem.type === "group"
    })

    const embedConv = conv.filter((curElem) => {
        return curElem.type === "embed"
    })


    useEffect(() => {
        if (staffList.length > 0) {
            setstaffs(staffList.map((curElem, index) => {
                if (index === 0) {
                    return { value: '', label: 'All' }
                }
                return {
                    label: curElem.name,
                    value: curElem.name
                }
            }))
        }
    }, [staffList])


    const fetchStaffs = () => {
        dispatch(fetchDataRedux("fetch-hired-staff", {}, "FETCH_STAFF_LIST", false, false, true))
    }

    useEffect(() => {
        fetchConv()
        fetchStaffs()
    }, [])


    return (
        <>
            <Header />
            <TitleBar title="Conversations" />
            <div className="site-wrapper">
                <div className="site-container">

                    <div className="outer-container">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>All Conversations</h2>
                                <p>Search chats with AIstaffs here</p>
                            </div>
                            <div className="titleBar-right">
                                <button className="theme-btn sm-w-100" onClick={handleShow}><span><AiFillPlusCircle /> New Conversation</span></button>
                            </div>
                        </div>
                    </div>

                    <div className="department-list mt-2">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                            <Nav variant="n" className="navStyle-short">
                                <div className="navStyle-short-wrap">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Staff Conversation</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Project Conversation</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Embed Conversation</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="department-list mt-5">

                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <h4 className="fw-700">Staff Conversations</h4>
                                            </div>
                                            <div className="col-auto">
                                                <div className="select-style alt">
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeS", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="input-wrap mt-0">
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qs}
                                                            onChange={(e) => handleChange("qs", e.target.value)}
                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {staffConv.length > 0 ?
                                                staffConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qs.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeS !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeS)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SingleConv
                                                                type="single"
                                                                curElem={curElem}
                                                                fetchConv={fetchConv}
                                                            />
                                                        </React.Fragment>

                                                    )
                                                })
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no staff conversation created yet."}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="department-list mt-5">

                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <h4 className="fw-700">Staff Conversations</h4>
                                            </div>
                                            <div className="col-auto">
                                                <div className="select-style alt">
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeP", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="input-wrap mt-0">
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qp}
                                                            onChange={(e) => handleChange("qp", e.target.value)}

                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {projConv.length > 0 ?
                                                projConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qp.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeP !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeP)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SingleConv
                                                                type="group"
                                                                curElem={curElem}
                                                                fetchConv={fetchConv}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no project conversation created yet."}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="department-list mt-5">

                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <h4 className="fw-700">Staff Conversations</h4>
                                            </div>
                                            <div className="col-auto">
                                                <div className="select-style alt">
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeE", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="input-wrap mt-0">
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qe}
                                                            onChange={(e) => handleChange("qe", e.target.value)}
                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {embedConv.length > 0 ?
                                                embedConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qe.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeE !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeE)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <div className="col-sm-6 col-lg-4 col-xl-3" key={index}>
                                                            <div className="chat-box">
                                                                <div className="chat-box-top">
                                                                    <Link>
                                                                        <div className="chat-box-top-in">
                                                                            <div className="chat-box-img"><img alt="" src={require('../../../../assets/images/user.jpg')} /></div>
                                                                            <div className="chat-box-icon"><BsFillChatDotsFill /></div>
                                                                        </div>
                                                                    </Link>

                                                                    <div className="user-nav">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                                                <div className="drop-icon"><HiDotsVertical /></div>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <ul>
                                                                                    <li><Link to="/">Action</Link></li>
                                                                                    <li><Link to="/">Another action</Link></li>
                                                                                    <li><Link to="/">Something else</Link></li>
                                                                                </ul>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                                <div className="chat-box-bottom">
                                                                    <h6>How to design UI with</h6>
                                                                    <p>24-07-2023 - 09:30AM</p>
                                                                    <p className="mt-2"><BsLink45Deg /> www.aicallings.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no embed conversation created yet."}
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Footer />

            <CreateConvModal
                show={show}
                handleClose={handleClose}
                fetchConv={fetchConv}
            />
        </>
    )
}

export default ChatNew;
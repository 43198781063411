import React from "react";

import {RiRobot2Fill} from 'react-icons/ri'
import{AiOutlineEyeInvisible} from "react-icons/ai"

import { Link } from "react-router-dom";

import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";

function Register() {

    return(
        <>
        <div className="login-header"><LoginHeader></LoginHeader></div>
          <div className="login-wrap">
            <div className="login-box">
                <div className="symbol"><RiRobot2Fill/></div>
                <h2>Register to AIStaff</h2>
                <div className="login-form">
                    <div className="input-wrap">
                        <label htmlFor="">Username</label>
                        <div className="inp-outer">
                          <input className="inp" type="text" placeholder="Johnson" />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Email Address</label>
                        <div className="inp-outer">
                          <input className="inp" type="text" placeholder="Johnsonwillamson@gmail.com" />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Passoword</label>
                        <div className="inp-outer">
                          <input className="inp" type="password" value="Password" />
                          <span className="inp-icon"><AiOutlineEyeInvisible/></span>
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Confirm Passoword</label>
                        <div className="inp-outer">
                          <input className="inp" type="password" value="Password" />
                          <span className="inp-icon"><AiOutlineEyeInvisible/></span>
                        </div>
                    </div>
                    <div className="input-wrap">
                        <Link to="/dashboard-1"><button className="theme-btn full"><span>Register</span></button></Link>
                    </div>

                </div>
            </div>

            <div className="log-option">Already have an account?  <Link to="/">Login</Link></div>
          </div>

          <div className="login-footer"><Footer></Footer></div>
        </>
    )
}

export default Register;
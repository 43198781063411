import React, { useState } from "react";

import ChatHeaderAuto from "../../Header/ChatHeaderAuto";
import{BsPlusCircleFill, BsImages, BsUpload, BsStars} from "react-icons/bs"
import{PiMicrophoneBold} from "react-icons/pi"
import{FiSend} from "react-icons/fi"
import{HiDotsVertical} from "react-icons/hi"
import{MdAlternateEmail, MdOutlineFileDownload} from "react-icons/md"
import{AiOutlineEye} from "react-icons/ai"
import{FiDownload} from "react-icons/fi"
import{AiOutlineClose} from "react-icons/ai"

import { Link, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import { Modal } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';


function AutoMode() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
        <ChatHeaderAuto></ChatHeaderAuto>

        <div className="site-wrapper forChat">
           <div className="chat-container">
            <div className="messages">

                <div className="message-wrap">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo"><img alt="" src={require('../../../assets/images/user-7.png')} /></div>
                            <div className="message-head">
                                <span>Mark Gallegos</span>
                                <span>09:00 AM</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt">
                                    <p>Hi, Are you still Web Designer.</p>
                                </div>
                                <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical/></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="message-single">
                        <div className="message-group">
                            <div className="message-body">
                                <div className="message-txt">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                </div>
                                <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical/></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <div className="msg-tags">Tags <span>@Alexandra</span> <span><BsImages/> Image</span></div>
                        </div>
                    </div>
                </div>

                <div className="message-wrap own-message">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo"><img alt="" src={require('../../../assets/images/user-8.png')} /></div>
                            <div className="message-head">
                                <span>You</span>
                                <span>09:00 AM</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt">
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical/></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <div className="msg-tags">Tags <span>@Alexandra</span> <span><BsImages/> Image</span></div>
                        </div>
                    </div>
                </div>

                <div className="message-wrap">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo"><img alt="" src={require('../../../assets/images/user-9.png')} /></div>
                            <div className="message-head">
                                <span>Alexandra Wills</span>
                                <span>09:00 AM</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt">
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical/></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="message-wrap auto-message">
                    <div className="message-single">
                        <div className="message-group">
                            <div className="user-photo">
                                <img alt="" src={require('../../../assets/images/user-10.png')} />
                                <div className="user-photo-mark"><img alt="" src={require('../../../assets/images/star.png')} /></div>
                            </div>
                            <div className="message-head">
                                <span></span>
                                <span>09:00 AM</span>
                            </div>
                            <div className="message-body">
                                <div className="message-txt">
                                    <div className="auto-header">
                                        <h3>Goal : UI/UX design tips and tricks for designing Saas</h3>
                                        <div className="user-nav">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><MdOutlineFileDownload /> Export</div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <ul>
                                                        <li><Link to="/"><MdOutlineFileDownload /> PDF Export</Link></li>
                                                        <li><Link to="/"><MdOutlineFileDownload /> DOC Export</Link></li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    

                                    <div className="theme-accord">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <div>
                                                        <p>Added task <img alt="" src={require('../../../assets/images/tick.png')} /></p>
                                                        <span>UI/UX design tips and tricks for designing Saas</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. Mattis nunc sed blandit libero volutpat sed.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <div>
                                                        <p>Added task</p>
                                                        <span>UI/UX design tips and tricks for designing Saas</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. Mattis nunc sed blandit libero volutpat sed.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <div>
                                                        <p>Added task</p>
                                                        <span>UI/UX design tips and tricks for designing Saas</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisi porta lorem mollis aliquam. Et tortor at risus viverra. Potenti nullam ac tortor vitae purus faucibus ornare. Euismod elementum nisi quis eleifend quam adipiscing vitae. Sapien et ligula ullamcorper mal proin libero nunc consequat. Velit laoreet id donec ultrices tincidunt arcu. At consectetur lorem donec massa. Enim diam vulputate ut pharetra sit amet. Vel fringilla est ullamco eget nulla facilisi etiam. Est ante in nibh mauris. Ac felis donec et odio pellentesque. Mattis nunc sed blandit libero volutpat sed.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>

                                    <div className="typing">
                                        <div>
                                            <p>Added task</p>
                                            <span>UI/UX design tips and tricks for designing Saas</span>
                                        </div>
                                        <div className="d-flex align-items-center mt-3 typing-div">
                                            <img alt="" src={require('../../../assets/images/user-8.png')} />
                                            <h6>Alexandra Typing Answer ...</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="task-addition">
                                    <p>Maximum Tasks</p>
                                    <input className="inp alt" type="text" placeholder="10" />
                                    <button className="theme-btn"><span><BsStars/> Add Task</span></button>
                                </div>

                                <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical/></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/">Action</Link></li>
                                            <li><Link to="/">Another action</Link></li>
                                            <li><Link to="/">Something else</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           </div>
           <div className="chatscreen-bottom">
            <div className="chat-container">
                <div className="inp-outer icon-left">
                    <input type="text" className="inp alt" placeholder="Type new questions" />

                    <span className="inp-icon">
                    <div className="user-nav">
                        <Dropdown>
                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                            <div className="drop-icon"><BsPlusCircleFill/></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><BsImages/></div>
                                    <div className="act-menu-txt">
                                        <h6>Image Prompt</h6>
                                        <p>Generate image reply</p>
                                    </div>
                                </div>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><MdAlternateEmail/></div>
                                    <div className="act-menu-txt">
                                        <h6>Mention Staff</h6>
                                        <p>Mention Staff for reply</p>
                                    </div>
                                </div>
                                <div className="act-menu">
                                    <div className="embed-user-icon"><BsUpload/></div>
                                    <div className="act-menu-txt">
                                        <h6>Upload Image</h6>
                                        <p>Input image in prompt</p>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </span>

                    <div className="chat-icon">
                        <span><PiMicrophoneBold/></span>
                        <span><FiSend/></span>
                    </div>
                </div>
                <p className="chat-info">You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
            </div>
           </div>
        </div>


        <Modal className="VideoModal modal-700 no-bg" show={show} onHide={handleClose} centered>
            <Modal.Body>
               <div>
                <div className="closePrev"><span onClick={handleClose}>Close Preview <AiOutlineClose/></span></div>
                <p>Image 2</p>
                <p>Dog driving on Bike, Realistic Image, Digital art, wearing helmet, Clean image </p>
                <div className="modal-img"><img alt="" src={require('../../../assets/images/modal-img.jpg')} /></div>
                <div className="modal-img-download">
                    <button className="theme-btn grey"><span><FiDownload/> Download Image</span></button>
                </div>
               </div>
            </Modal.Body>
        </Modal>
          
        </>
    )
}

export default AutoMode;
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { HiDotsVertical } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import CommonAlert from '../../../CommonComponents/CommonAlert'

const SingleConv = ({ curElem, fetchConv, type }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-conversation", data, false, false, setSweet, fetchConv))
    }

    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <div className="chat-box">
                <div className="chat-box-top">
                    <Link to={type === "single" ? `/chat-screen?id=${curElem.id}&type=s` :
                        type === "group" ?
                            `/chat-screen?id=${curElem.id}&cid=${curElem.companyId}&did=${curElem.departmentId}&pid=${curElem.projectId}&type=p` : ""}>
                        {type === "group" ?
                            <div className="user-stack">
                                {curElem.staff.length > 0 ?
                                    curElem.staff.slice(0, 3).map((curElem, index) => {
                                        return (
                                            <div className="user-stack-single" key={index}><img src={curElem.image} alt="" /></div>
                                        )
                                    })

                                    : ""}
                                <div className="user-stack-single last"><span>{curElem.staff.length > 4 ? "4+" : curElem.staff.length}</span></div>
                            </div> :
                            curElem.staff.length > 0 ?
                                <div className="chat-box-top-in">
                                    <div className="chat-box-img"><img alt="" src={curElem.staff[0].image} /></div>
                                    <div className="chat-box-icon"><BsFillChatDotsFill /></div>
                                </div> : ""
                        }
                    </Link>
                    <div className="user-nav">
                        <Dropdown>
                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                <div className="drop-icon"><HiDotsVertical /></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    <li><Link >Download</Link></li>
                                    <li><a style={{ cursor: "pointer" }} onClick={onDelete}>Delete</a></li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="chat-box-bottom">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.created}</p>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this conversation?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default SingleConv
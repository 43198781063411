import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
        email: "",
        website: "",
        category: "",
        image: ""
    },
}

export const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_COMPANY_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_COMPANY":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })
        case "EDIT_COMPANY":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "DELETE_COMPANY":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "UNMOUNT_CREATE_COMPANY":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })
        default:
            return state
    }
}
import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
        staff: [],
        projectStatus: "active"
    },
    conversationList: [],
    activeConv: false
}

export const ProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PROJECT_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_PROJECT":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })
        case "EDIT_PROJECT":
            return produce(state, (draft) => {
                draft.create = action.payload
            })
        case "DELETE_PROJECT":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "ACTIVE_CONVERSATION":
            return produce(state, (draft) => {
                draft.activeConv = action.payload
            })

        case "FETCH_CONVERSATIONS_LIST":
            return produce(state, (draft) => {
                draft.conversationList = action.payload
            })

        case "DEFAULT_CONV_ACTIVE":
            return produce(state, (draft) => {
                if (draft.conversationList.length > 0) {
                    draft.activeConv = draft.conversationList.find(({ id }) => +id === +action.payload)
                }
            })

        case "ON_DELETE_CONV":
            return produce(state, (draft) => {
                if (+action.payload === +draft.activeConv.id) {
                    draft.activeConv = false
                }
                draft.conversationList = draft.conversationList.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })

        case "UNMOUNT_CREATE_PROJECT":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })
        default:
            return state
    }
}
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { onDeleteData } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { FaEdit, FaTrash } from 'react-icons/fa'

const DepartmentRows = ({ curElem }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-department", data, "DELETE_DEPARTMENT", setSweet))
    }

    const onUpdateDepartment = (e) => {
        e.stopPropagation()
        navigate(`/create-departments?cid=${curElem.companyId}&did=${curElem.id}`)
    }

    const onProejcts = () => {
        navigate(`/projects?cid=${curElem.companyId}&did=${curElem.id}`)
    }

    return (
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <span onClick={onProejcts} style={{ cursor: "pointer" }}>
                <div className="department-single" style={{ position: "relative" }}>

                    {/* <div style={{ position: "absolute", right: 2, top: 2 }}>
                        <span onClick={(e) => onUpdateDepartment(e)}><FaEdit size={20} /></span>
                        <span onClick={(e) => onDelete(e)} className="mx-1"><FaTrash size={20} /></span>
                    </div> */}

                    <div className="department-single-img">
                        <img alt="" src={curElem.image} />
                        <div className='department-single-img-hov'>
                               <span className='round-btn' onClick={(e) => onUpdateDepartment(e)}><FaEdit /></span>
                               <span className='round-btn' onClick={(e) => onDelete(e)}><FaTrash /></span>
                        </div>
                    </div>
                    <div className="department-single-txt">
                        <h4>{curElem.name}</h4>
                        <p>5 Projects</p>
                    </div>
                </div>
            </span>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this department?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default DepartmentRows
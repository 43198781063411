import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { fetchDataRedux } from '../../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import { onCreateConversation } from '../../../../Redux/Actions/ProjectActions'
import { FaSpinner } from 'react-icons/fa'

const CreateConvModal = ({ show, handleClose, fetchConv }) => {
    const dispatch = useDispatch()
    const staffList = useSelector(state => state.staff.list)
    const [loader, setLoader] = useState({
        create: false
    })
    const [data, setData] = useState({
        name: "",
        type: "single",
        hireId: "",
        tone: "",
        respose: "",
        language: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateConversation(data, false, handleClose, loader, setLoader, fetchConv))
    }

    const fetchStaffs = () => {
        dispatch(fetchDataRedux("fetch-hired-staff", {}, "FETCH_STAFF_LIST"))
    }

    useEffect(() => {
        fetchStaffs()
    }, [])

    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>

            <Modal.Body className="p-5">
                <form onSubmit={handleSubmit}>
                    <div className="company-block-title">
                        <div>
                            <h3>Create New Conversation</h3>
                            <p>Give a chat name to start a new conversation</p>
                        </div>
                        <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Conversation Name</label>
                        <div className="inp-outer">
                            <input
                                className="inp"
                                type="text"
                                name="name"
                                placeholder="Type Chat Name"
                                value={data.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="">Select A Staff</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                name="hireId"
                                value={data.hireId}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Your Hired Stuffs</option>
                                {staffList.length > 0 ?
                                    staffList.filter((curElem) => {
                                        return +curElem.isFire === 0
                                    }).map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.id}><img src={curElem.image}/> {curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                    <div className="input-wrap">
                        <button className="theme-btn full" type='submit'><span>{loader.create ? <>Creating <FaSpinner className='spin' /></> : "Create New Conversation"}</span></button>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}

export default CreateConvModal
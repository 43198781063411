import React, { useEffect, useState } from "react";

import EmbedHeader from "../../../Header/EmbedHeader";
import Footer from "../../../Footer/Footer";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import Select from 'react-select'


import { Link, NavLink, useLocation } from "react-router-dom";
import { BiTrash, BiSolidCopy, BiChevronLeft } from "react-icons/bi"
import { BsCheckLg, BsGlobeAmericas } from "react-icons/bs"
import { AiOutlineClose, AiFillFile, AiOutlineEye } from "react-icons/ai"
import { MdOutlineModeEdit } from "react-icons/md"

import { RiCloseFill } from "react-icons/ri"
import TitleBar from "../../../CommonComponents/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchDirectory } from "../../../../Redux/Actions/DirectoryActions";
import WriteDoc from "../../Projects/KnowledgeBase/Write/WriteDoc";
import UploadDoc from "../../Projects/KnowledgeBase/Upload/UploadDoc";
import ImportDoc from "../../Projects/KnowledgeBase/Import/ImportDoc";

const UpdateDirectory = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const directoryData = useSelector(state => state.directory.updateData)
    const [loader, setLoader] = useState({
        fetch: true
    })

    console.log(directoryData)

    const fetchDirectory = () => {
        let data = {
            id
        }
        dispatch(onFetchDirectory(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchDirectory()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Update Directory" />

                <header className="site-header">
                    <div className="site-container">
                        <div className="site-header-main">
                            <div className="backBtn alt">
                                <Link className="back" to={`/youai`}><BiChevronLeft /></Link>
                                <h6>Directories</h6>
                            </div>
                            <div className="header-right">
                                {/* <Link className="theme-btn bdr" ><span>Next</span></Link> */}
                                {/* <Link className="theme-btn"><span>Update</span></Link> */}
                            </div>
                        </div>
                    </div>
                </header>

                <div className="embed-progress">
                    <div className="embed-progress-in"></div>
                </div>

                <div className="site-wrapper">
                    <div className="site-container">
                        <div className="embed-wrap width-700">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Update Training Documents - {directoryData.main.name}</h2>
                                    <p>Add Document details and train Ai</p>
                                </div>
                            </div>

                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="b" className="navStyle third">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">
                                                <h6>Write</h6>
                                                <p>Write training document</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">
                                                <h6>Upload</h6>
                                                <p>Upload your document</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                <h6>Import Website</h6>
                                                <p>Import from website URL</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="pt-5">
                                        <Tab.Pane eventKey="first">
                                            <WriteDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-manual"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />

                                            {/* <div className="input-wrap mt-0">
                                                <label htmlFor="">Document Title</label>
                                                <div className="inp-outer">
                                                    <input className="inp" type="text" placeholder="Type Document Title" />
                                                </div>
                                            </div>

                                            <div className="input-wrap">
                                                <label htmlFor="">Content</label>
                                                <div className="inp-outer">
                                                    <textarea className="inp" name="" id="" placeholder="Write project name"></textarea>
                                                </div>
                                            </div> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <UploadDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-extract"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />
                                            {/* <div className="input-wrap mt-0">
                                                <label htmlFor="">Upload Your Document</label>
                                                <div className="file-field">
                                                    <div className="me-3"><img alt="" src={require('../../../../assets/images/icon/img.png')} /></div>
                                                    <div>
                                                        <h6>Click to upload or drag and drop file like image, PDF & DOCX</h6>
                                                    </div>
                                                    <input type="file" />
                                                </div>
                                            </div>

                                            <div className="shared">
                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><AiFillFile /></div>
                                                        <div className="shared-txt">
                                                            <h6>Design Ai Staff Document.docx</h6>
                                                            <p>Uploading...</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineClose /></span>
                                                    </div>
                                                    <div className="uploadBar">
                                                        <div className="uploadBar-in"></div>
                                                    </div>
                                                </div>

                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><AiFillFile /></div>
                                                        <div className="shared-txt">
                                                            <h6>UI Design.Pdf</h6>
                                                            <p>Created . 2023-09-03 - 04:26 PM</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineEye /></span>
                                                        <span><MdOutlineModeEdit /></span>
                                                        <span><BiTrash /></span>
                                                    </div>
                                                </div>

                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><AiFillFile /></div>
                                                        <div className="shared-txt">
                                                            <h6>GPT Design Prompts.pdf</h6>
                                                            <p>Created . 2023-09-03 - 04:26 PM</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineEye /></span>
                                                        <span><MdOutlineModeEdit /></span>
                                                        <span><BiTrash /></span>
                                                    </div>
                                                </div>

                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><AiFillFile /></div>
                                                        <div className="shared-txt">
                                                            <h6>Graphic design fundamentals.docx</h6>
                                                            <p>Created . 2023-09-03 - 04:26 PM</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineEye /></span>
                                                        <span><MdOutlineModeEdit /></span>
                                                        <span><BiTrash /></span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="third">
                                            <ImportDoc
                                                ids={{ directoryId: id }}
                                                fetchData={fetchDirectory}
                                                url="create-knowledge-website"
                                                kdData={directoryData.main.data}
                                                type="directory"
                                            />
                                            {/* <div className="input-wrap mt-0">
                                                <label htmlFor="">Website URL</label>

                                                <div className="inp-flex">
                                                    <div className="inp-outer">
                                                        <input className="inp" type="text" placeholder="https://www.google.com" />
                                                    </div>
                                                    <button className="theme-btn"><span>Import</span></button>
                                                </div>
                                            </div>

                                            <div className="shared">
                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><BsGlobeAmericas /></div>
                                                        <div className="shared-txt">
                                                            <h6>https://www.google.com</h6>
                                                            <p>Uploading...</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineClose /></span>
                                                    </div>
                                                    <div className="uploadBar">
                                                        <div className="uploadBar-in"></div>
                                                    </div>
                                                </div>

                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><BsGlobeAmericas /></div>
                                                        <div className="shared-txt">
                                                            <h6>https://www.Aistaff.com</h6>
                                                            <p>Created . 2023-09-03 - 04:26 PM</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineEye /></span>
                                                        <span><MdOutlineModeEdit /></span>
                                                        <span><BiTrash /></span>
                                                    </div>
                                                </div>

                                                <div className="shared-single">
                                                    <div className="shared-left">
                                                        <div className="embed-user-icon"><BsGlobeAmericas /></div>
                                                        <div className="shared-txt">
                                                            <h6>https://www.Upwork.com</h6>
                                                            <p>Created . 2023-09-03 - 04:26 PM</p>
                                                        </div>
                                                    </div>
                                                    <div className="shared-right">
                                                        <span><AiOutlineEye /></span>
                                                        <span><MdOutlineModeEdit /></span>
                                                        <span><BiTrash /></span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>

            </>
    )
}

export default UpdateDirectory;
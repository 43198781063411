import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsImages } from 'react-icons/bs'
import { HiDotsVertical } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const AssistantMessage = ({ curElem, socket, messages, setMessages, index, activeConv }) => {
    const [activeBlink, setActiveBlink] = useState(false)
    const [activeText, setActiveText] = useState("")

    const [assiData, setAssiData] = useState({
        image: "",
        name: ""
    })
    useEffect(() => {
        if (socket) {
            let msg = "";
            const handleReceivedResponse = (res) => {
                switch (res.status) {
                    // case "start":
                    //     setActiveBlink(true);
                    //     break;
                    case "progress":
                        if (!activeBlink) {
                            setActiveBlink(true);
                        }
                        msg += res.message;
                        setActiveText(prev => prev + res.message);
                        break;
                    case "complete":
                        setActiveBlink(false);
                        setActiveText("");
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            const lastIndex = updatedMessages.length - 1;
                            if (lastIndex >= 0) {
                                updatedMessages[lastIndex] = {
                                    ...updatedMessages[lastIndex],
                                    message: msg
                                };
                            }
                            return updatedMessages;
                        });
                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [socket])


    useEffect(() => {
        if (curElem && activeConv.staff.length > 0) {
            let staffData = activeConv.staff.find(({ id }) => +id === +curElem.staffId)
            if (staffData) {
                setAssiData({
                    ...assiData,
                    image: staffData.image,
                    name: staffData.name
                })
            }
        }
    }, [curElem])

    return (
        <div className="message-wrap">
            <div className="message-single">
                <div className="message-group">
                    <div className="user-photo"><img alt="" src={assiData.image} /></div>
                    <div className="message-head">
                        <span>{assiData.name}</span>
                        <span className='ms-2'>{curElem.created}</span>
                    </div>
                    <div className="message-body">
                        <div className="message-txt">
                            <p>
                                {activeBlink && (messages.length - 1 === index) ? activeText : curElem.message}
                            </p>
                        </div>
                        <div className="user-nav">
                            <Dropdown>
                                <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical /></div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <li><Link to="/">Action</Link></li>
                                        <li><Link to="/">Another action</Link></li>
                                        <li><Link to="/">Something else</Link></li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssistantMessage
import React, { useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import IntegrationAcc from "./IntegrationAcc";
import { useSelector } from "react-redux";

const Integrations = () => {
    const socials = useSelector(state => state.social)
    return (
        <>
            <TitleBar title="Integrations" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />
                        <div className="account-right">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Integrations</h2>
                                    <p>You can change and manage account details</p>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <div className="integrations">
                                    <IntegrationAcc
                                        name="Google Calendar"
                                        desc="For Importing your google calendar"
                                        image={require('../../../../assets/images/icon/google.png')}
                                        type="GC"
                                        data={socials.google}
                                    />

                                    <IntegrationAcc
                                        name="Facebook"
                                        type="FB"
                                        desc="Payment processing through stripe"
                                        image={require('../../../../assets/images/icon/facebook-logo.svg').default}
                                        data={socials.facebook}
                                    />

                                    <IntegrationAcc
                                        name="Cal.com"
                                        type="CC"
                                        desc="For Integrating with other tools"
                                        image={require('../../../../assets/images/icon/cal-com.svg').default}
                                        data={socials.cal}
                                    />

                                    <IntegrationAcc
                                        name="ElevenLabs"
                                        desc="Social media for sharing"
                                        type="EL"
                                        url="create-elevenlabs"
                                        image={require('../../../../assets/images/icon/elevenlabs.png')}
                                        data={socials.elevenlabs}
                                    />

                                    <IntegrationAcc
                                        name="Instagram"
                                        desc="Messaging to teams"
                                        type="IG"
                                        image={require('../../../../assets/images/icon/instagram.svg').default}
                                        data={socials.instagram}
                                    />

                                    <IntegrationAcc
                                        name="Autoresponder"
                                        desc="Video call app"
                                        type="AR"
                                        url="create-autoresponder"
                                        image={require('../../../../assets/images/icon/autoresponder.png')}
                                        data={socials.autoresponder}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <Footer></Footer>
        </>
    )
}

export default Integrations;
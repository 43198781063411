import { useDispatch } from "react-redux"
import { forgetPassword } from "../../../Redux/Actions/AuthActions"
import { useState } from "react"
import LoginHeader from "../../Header/LoginHeader"
import { RiRobot2Fill } from "react-icons/ri"
import { appName } from "../../../Global/Global"
import { FaSpinner } from "react-icons/fa"
import { Link } from "react-router-dom"
import Footer from "../../Footer/Footer"
import TitleBar from "../../CommonComponents/TitleBar"

const ForgotPassword = () => {

  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(forgetPassword(data, setLoader))
  }
  return (
    <>
      <TitleBar title="Forgot Password" />
      <div className="login-header"><LoginHeader /></div>
      <div className="login-wrap">
        <div className="login-box">
          <div className="symbol"><RiRobot2Fill /></div>
          <h2>Enter Your Email Address</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-wrap">
              <label htmlFor="">Email Address</label>
              <div className="inp-outer">
                <input
                  className="inp"
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  required
                />
              </div>
            </div>

            <div className="input-wrap">
              <button className="theme-btn full" type="submit"><span>{loader ? <>Submitting <FaSpinner className="spin ms-1" /></> : "Submit"}</span></button>
            </div>

          </form>
        </div>

        <div className="log-option">Remember? <Link to="/">Sign in</Link></div>
      </div>

      <div className="login-footer"><Footer /></div>
    </>
  )
}

export default ForgotPassword;
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import { fetchDataRedux } from "./CommonActions"

export const onCreateCustomStaff = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate('/youai')
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSaveWorkingHours = (data, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-working-hours", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                save: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onfetchCustomStatff = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-custom-staff", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "FETCH_CUSTOMESTAFF", payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onHireStaff = (url, data, handleClose, handleChange, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                dispatch(fetchDataRedux("fetch-hired-staff", {}, "FETCH_STAFF_LIST"))
                if (url === "hire-staff") {
                    handleChange("type", "hire")
                }
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCreateVoiceClone = (data, fetchCamp, setFileData, setName, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("create-clone-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setFileData({
                    url: "",
                    type: "",
                    localPath: ""
                })
                setName("")
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onSaveVoiceClone = (data, fetchCamp, setName, handleClose, setLoader, setFileData) => (dispatch, getState) => {
    commonAxios("save-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setName("")
                setFileData({
                    url: "",
                    type: "",
                    localPath: ""
                })
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}



// No Axios Here

export const onChangeStaff = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_STAFF", payload: { name, value } })
}

export const onChangeCustomStaff = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_CUSTOMSTAFF", payload: { name, value } })
}

export const onSetStaffToHire = (curElem) => (dispatch) => {
    dispatch({ type: "ON_SET_HIRES", payload: curElem })
}

export const onUnmountStaffToHire = (curElem) => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_HIRES" })
}



export const onHandleFolder = (isAdded, curElem) => (dispatch) => {
    dispatch({ type: "HANDLE_FOLDER", payload: { isAdded, curElem } })
}
export const onChangeHireStaff = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_HIRESTAFF", payload: { name, value } })
}

export const unmountCreateStaff = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_STAFF" })
}


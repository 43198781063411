import React, { useEffect, useState } from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import { BiSearch, BiDotsHorizontalRounded, BiTrash } from "react-icons/bi"
import { AiFillClockCircle } from "react-icons/ai"
import { BsPlusCircleFill, BsImages, BsUpload } from "react-icons/bs"
import { MdEdit, MdOutlineRefresh } from "react-icons/md"
import Select from 'react-select'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation } from "react-router-dom";
import { fetchData, fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import StaffRows from "./StaffRows";
import { FaSpinner, FaRobot, FaFolderOpen } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md"
import { HiPlusCircle } from "react-icons/hi";
import TitleBar from "../../CommonComponents/TitleBar";
import queryString from "query-string";


const HireAvailableStuffs = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { type } = queryString.parse(location.search)
    const staff = useSelector(state => state.staff)

    const [showStaffs, setShowStaffs] = useState([])
    const [experties, setExperties] = useState([])
    const [expertiesHire, setExpertiesHire] = useState([])

    const [filterObj, setFilterObj] = useState({
        type: "hire",
        q: "",
        experties: { value: '', label: 'All' },
        staffType: { value: '', label: 'All' }
    })
    const [loader, setLoader] = useState({
        fetch: true
    })

    const staffType = [
        { value: '', label: 'All' },
        { value: 'aiStaff', label: 'Ai Staffs' },
        { value: 'youAiStaff', label: 'YouAi Agent' }
    ]

    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }


    const fetchStaffs = () => {
        dispatch(fetchDataRedux("fetch-hired-staff", {}, "FETCH_STAFF_LIST", loader, setLoader, true))
    }


    const fetchExperties = () => {
        dispatch(fetchData("fetch-expertise", {}, setExperties))
        dispatch(fetchData("fetch-hire-expertise", {}, setExpertiesHire))
    }

    useEffect(() => {
        let arr = []
        if (filterObj.type === "fire") {
            arr = staff.list.filter((curElem) => {
                return +curElem.isFire === 1 && filterObj.type === "fire"
            })

        } else {
            arr = staff.list.filter((curElem) => {
                return +curElem.isFire === 0 && filterObj.type === "hire"
            })
        }
        arr = arr.filter((curElem) => {
            return curElem.name.toLowerCase().includes(filterObj.q.toLowerCase())
        }).filter((curElem) => {
            return curElem.category === filterObj.experties.value || filterObj.experties.value === ""
        }).filter((curElem) => {
            return curElem.type === filterObj.staffType.value || filterObj.staffType.value === ""
        })
        setShowStaffs(arr)
    }, [staff.list, filterObj])

    useEffect(() => {
        if (type && type === "av") {
            handleChange("type", "fire")
        }
    }, [type])

    useEffect(() => {
        fetchStaffs()
        fetchExperties()
    }, [])

    return (
        <>
            <TitleBar title="Staffs" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container">
                        <div className="titleBar forStaff">
                            <div className="titleBar-left">
                                <h2>My Staffs</h2>
                                <p>Search Staffs and Hire Staffs</p>
                            </div>
                            <div className="titleBar-right">
                                <div className="staffBtn">
                                    <button className={filterObj.type === "hire" ? "active" : ""} onClick={() => handleChange("type", "hire")}><span>Hired Staff</span></button>
                                    <button className={filterObj.type === "fire" ? "active" : ""} onClick={() => handleChange("type", "fire")}><span>Available Staff</span></button>
                                </div>
                            </div>
                        </div>

                        <div className="staff-sort">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="input-wrap alt mt-0">
                                        <div className="inp-outer icon-left">
                                            <input
                                                className="inp"
                                                type="text"
                                                placeholder="Search"
                                                value={filterObj.q}
                                                onChange={(e) => handleChange("q", e.target.value)}
                                            />
                                            <span className="inp-icon"><BiSearch /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="staff-sort-single">
                                        <div className="select-style alt">
                                            <Select
                                                options={filterObj.type === "hire" ? expertiesHire.map((curElem, index) => {
                                                    if (index === 0) {
                                                        return {
                                                            label: "All",
                                                            value: ""
                                                        }
                                                    }
                                                    return {
                                                        label: curElem.category,
                                                        value: curElem.category
                                                    }
                                                }) :
                                                    experties.map((curElem, index) => {
                                                        if (index === 0) {
                                                            return {
                                                                label: "All",
                                                                value: ""
                                                            }
                                                        }
                                                        return {
                                                            label: curElem,
                                                            value: curElem
                                                        }
                                                    })}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={filterObj.experties}
                                                onChange={(e) => handleChange("experties", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="staff-sort-single">
                                        <div className="select-style alt">
                                            <Select
                                                options={staffType}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={filterObj.staffType}
                                                onChange={(e) => handleChange("staffType", e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="outer-container">
                        <div className="staff-list">
                            <div className="row">
                                {showStaffs.length > 0 ?
                                    showStaffs.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <StaffRows
                                                    curElem={curElem}
                                                    fetchStaffs={fetchStaffs}
                                                    handleChange={handleChange}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className="text-center mt-4">
                                        {loader.fetch ?
                                            <FaSpinner className="spin" size={25} /> :
                                            "No staff available"
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default HireAvailableStuffs;
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onConnectInte } from '../../../../Redux/Actions/SocialActions'

const ConnectModal = ({ shortType, url, name, show, handleClose }) => {
    const FormDataJson = require('form-data-json-convert')

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false
    })
    const [state, setState] = useState({
        name: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...state }
        if (shortType === "AR") {
            delete data.apiKey
            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            formData.postUrl = form[0].action
            data = {
                ...data,
                name: data.name,
                code: data,
            }
        }

        dispatch(onConnectInte(url, data, handleClose, loader, setLoader))
    }

    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2>{name}</h2>
                        </div>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="input-wrap">
                        <label htmlFor="">Enter Name</label>
                        <div className="inp-outer">
                            <input
                                className="inp alt"
                                type="text"
                                name='name'
                                placeholder='Enter Name'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    {shortType === "AR" ?
                        <div className="input-wrap">
                            <label htmlFor="">Opt-in Code</label>
                            <div className="inp-outer">
                                <textarea
                                    className="inp alt"
                                    type="text"
                                    name='code'
                                    rows={5}
                                    placeholder='Enter Code Here'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        :
                        <div className="input-wrap">
                            <label htmlFor="">Enter API Key</label>
                            <div className="inp-outer">
                                <input
                                    className="inp alt"
                                    type="text"
                                    name='apiKey'
                                    placeholder='Enter API Key'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    }
                    <div className="input-wrap text-center">
                        <button className="theme-btn"><span>{loader.submit ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span></button>
                    </div>
                </form>

                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: state.code }} />
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal
import React, { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { MdOutlineEdit } from 'react-icons/md'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { onDeleteCampaign, onDeleteData } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { onActiveConv } from '../../../../Redux/Actions/ProjectActions'

const ConversationRows = ({ curElem, activeConv }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-conversation",data,"ON_DELETE_CONV", setSweet, ))
    }

    const handleActive = () => {
        dispatch(onActiveConv(curElem))
    }

    return (
        <li>
            <div className={`flexLink ${activeConv.id === curElem.id ? "active":""}`} style={{cursor :"pointer"}} onClick={handleActive}>
                <p>{curElem.name}</p>
                <div className="flexLink-icon" >
                    <button><MdOutlineEdit /></button>
                    <button onClick={(e) => onDelete(e)}><BiTrash /></button>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this conversation?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </li>
    )
}

export default ConversationRows
import React, { useEffect, useState } from 'react'
import { FaFile, FaRegTrashAlt, FaSpinner } from 'react-icons/fa'
import { FaCirclePlus } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { onUploadMultipleFiles } from '../../../../../Redux/Actions/CommonActions'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'
import { onSubmitKd } from '../../../../../Redux/Actions/ProjectActions'
import UploadedRows from '../UploadedRows'

const UploadDoc = (props) => {
    const dispatch = useDispatch()
    let checkTypeList = [
        "application/pdf",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        'image/png', 'image/jpg', 'image/jpeg'
    ]

    const [uploadData, setUploadData] = useState([])
    const [percent, setPercent] = useState(0)
    const [uploadStatus, setUploadStatus] = useState("Uploading...")

    const [loader, setLoader] = useState({
        upload: false,
        submit: false
    })
    const [files, setFiles] = useState([])
    const [urls, setUrls] = useState([])

    const onCompeleteUpload = (urls) => {
        setUrls(urls)
    }

    const handleSubmit = () => {
        if (urls) {
            setLoader({
                ...loader,
                submit: true
            })
            let obj = { ...props.ids }
            obj.type = "upload"
            obj.url = urls
            dispatch(onSubmitKd(props.url, obj, props.fetchData, setUrls,loader, setLoader, setFiles))
        } else {
            dispatch(setAlert("Please add files to continue!", "danger"))
        }
    }

    const onUploadeFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files
            let allFileOk = false
            const formData = new FormData()
            let arr = []
            for (let i = 0; i < fileData.length; i++) {
                let curElem = fileData[i]
                arr.push(curElem)
                if (checkTypeList.includes(curElem.type)) {
                    let size = 300000000
                    if (curElem.type.includes("image")) {
                        size = 5000000
                    }
                    if (curElem.size < size) {
                        allFileOk = true
                        formData.append("file[]", curElem)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `The maximum allowed size is ${curElem.type.includes("image") ? "5MB" : "300MB"}. You have provided file ${curElem.name}'s size is more than ${curElem.type.includes("image") ? "5MB" : "300MB"}!`,
                            confirmButtonColor: "#000"
                        })
                        allFileOk = false
                        break;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `You have selected invalid file "${curElem.name}".`,
                        confirmButtonColor: "#000"
                    })
                    allFileOk = false
                    break;
                }
            }
            if (allFileOk) {
                setFiles(arr)
                setLoader({
                    ...loader,
                    upload: true
                })
                dispatch(onUploadMultipleFiles(formData, onCompeleteUpload, loader, setLoader, setPercent, setUploadStatus))
            }
        }
    }

    useEffect(() => {
        setUploadData(props.kdData.filter((curElem) => {
            return curElem.type === "upload"
        }))
    }, [props.kdData])


    return (
        <>
            <div className="input-wrap mt-0">
                <label htmlFor="">Upload Your Document</label>
                <div className="file-field">
                    <div className="d-flex align-items-center justify-content-center"><FaCirclePlus /></div>
                    <div className="ps-2">
                        <h6>Click to upload or drag and drop file like image, PDF & DOCX</h6>
                    </div>
                    <input
                        type="file"
                        onChange={onUploadeFile}
                        accept={checkTypeList.join(",")}
                        multiple={true}
                    />
                </div>
            </div>

            <div className="input-wrap pt-1">
                {files.length > 0 ?
                    files.map((curElem, index) => {
                        return (
                            <div className="doc-list mt-4" key={index}>
                                <div className="uploadBar" style={{ width: `${percent}%` }}></div>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="doc-icon">
                                                    <FaFile /></div>
                                            </div>
                                            <div className="col">
                                                <h6>{curElem.name}</h6>
                                                <p>{uploadStatus}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    ""
                }
                <div className="input-wrap">
                    <button onClick={handleSubmit} className="theme-btn full" style={{ background: urls.length > 0 ? "" : "gray" }} disabled={urls.length === 0}><span>{loader.submit ? <>Updating <FaSpinner className='spin' /></> : "Update"}</span></button>
                </div>


                <label className="p-0 mt-2" htmlFor="">Stored Documents</label>

                {uploadData.length > 0 ?
                    uploadData.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <UploadedRows
                                    curElem={curElem}
                                    fetchFunction={props.fetchData}
                                    type={props.type}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        You do not have a file uploaded yet!
                    </div>

                }



            </div>


        </>
    )
}

export default UploadDoc
import queryString from "query-string"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { resetPassword } from "../../../Redux/Actions/AuthActions"
import LoginHeader from "../../Header/LoginHeader"
import { RiRobot2Fill } from "react-icons/ri"
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { FaSpinner } from "react-icons/fa"
import Footer from "../../Footer/Footer"
import TitleBar from "../../CommonComponents/TitleBar"


const ResetPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let location = useLocation()
    const [view1, setView1] = useState(false)
    const [view2, setView2] = useState(false)

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    return (
        <>
         <TitleBar title="Reset Password" />
            <div className="login-header"><LoginHeader /></div>
            <div className="login-wrap">
                <div className="login-box">
                    <div className="symbol"><RiRobot2Fill /></div>
                    <h2>Reset Password</h2>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="input-wrap">
                            <label htmlFor="">New Password</label>
                            <div className="inp-outer">
                                <input
                                    className="inp"
                                    type={view1 ? "text" : "password"}
                                    placeholder='***********'
                                    required
                                    onChange={handleChange}
                                    name="password"
                                />
                                <span
                                    className="inp-icon"
                                    onMouseUp={() => setView1(false)}
                                    onMouseLeave={() => setView1(false)}
                                    onMouseDown={() => setView1(true)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineEyeInvisible />
                                </span>
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Confirm Password</label>
                            <div className="inp-outer">
                                <input
                                    className="inp"
                                    type={view2 ? "text" : "password"}
                                    placeholder='***********'
                                    required
                                    onChange={handleChange}
                                    name="confirm_password"
                                />
                                <span
                                    className="inp-icon"
                                    onMouseUp={() => setView2(false)}
                                    onMouseLeave={() => setView2(false)}
                                    onMouseDown={() => setView2(true)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineEyeInvisible />
                                </span>
                            </div>
                        </div>
                        <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                        <div className="input-wrap">
                            <button className="theme-btn full" type="submit"><span>Reset Password {loader ? <FaSpinner className="spin ms-1" /> : null}</span></button>
                        </div>
                    </form>
                </div>
                <div className="log-option">Login? <Link to="/">Click here</Link></div>
            </div>

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default ResetPassword
import { produce } from "immer"

const initialState = {
    list: [],
    customList: [],
    create: {
        name: "",
        description: "",
        email: "",
        website: "",
        category: "",
        image: ""
    },
    createCustom: {
        name: "",
        gender: "",
        category: "",
        description: "",
        image: "",
        tone: "",
        writingStyle: "",
        directory: []
    },
    hire: {
        name: "",
        description: "",
        negativePrompt: "",
        image: "",
        voice: ""
    }
}

export const StaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_STAFF_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "FETCH_CUSTOMESTAFF_LIST":
            return produce(state, (draft) => {
                draft.customList = action.payload.filter((curElem) => {
                    return curElem.type === "youAiStaff"
                })
            })

        case "FETCH_CUSTOMESTAFF":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                obj.directory = obj.directory ? obj.directory : []
                draft.createCustom = obj
            })
        case "ON_CHANGE_STAFF":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })

        case "ON_CHANGE_CUSTOMSTAFF":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.createCustom = {
                    ...draft.createCustom,
                    [name]: value
                }
            })

        case "HANDLE_FOLDER":
            return produce(state, (draft) => {
                let { isAdded, curElem } = action.payload
                if (isAdded) {
                    const folIndex = draft.createCustom.directory.findIndex(({ id }) => +id === +curElem.id)
                    if (folIndex !== -1) {
                        draft.createCustom.directory.splice(folIndex, 1)
                    }
                } else {
                    draft.createCustom.directory.push(curElem)
                }

            })
        case "ON_SET_HIRES":
            return produce(state, (draft) => {
                draft.hire = {
                    ...draft.hire,
                    ...action.payload
                }
            })

        case "ON_UNMOUNT_HIRES":
            return produce(state, (draft) => {
                draft.hire = {
                    name: "",
                    description: "",
                    negativePrompt: "",
                    image: "",
                    voice: ""
                }
            })

        case "ON_CHANGE_HIRESTAFF":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.hire = {
                    ...draft.hire,
                    [name]: value
                }
            })

        case "EDIT_STAFF":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "DELETE_STAFF":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "UNMOUNT_CREATE_STAFF":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })
        default:
            return state
    }
}
import React from 'react'
import { BiLogOut, BiSupport } from 'react-icons/bi'
import { FaKey, FaShareAlt, FaUserCircle, FaUsersCog } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const ProfileSidebar = () => {
    return (
        <div className="account-left">
            <div className="account-nav">
                <ul>
                    <li><NavLink to="/my-account"><span><FaUserCircle /></span> My Account</NavLink></li>
                    <li><NavLink to="/privacy"><span><FaKey /></span> Privacy</NavLink></li>
                    <li><NavLink to="/integrations"><span><FaShareAlt /></span> Integrations</NavLink></li>
                    <li><NavLink to="/manage-team"><span><FaUsersCog /></span> Manage Team</NavLink></li>
                    <li><NavLink to="/help-support"><span><BiSupport /></span> Help and Support</NavLink></li>
                    {/* <li><NavLink to="/"><span><BiLogOut /></span> Logout</NavLink></li> */}
                </ul>
            </div>
        </div>
    )
}

export default ProfileSidebar
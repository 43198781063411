import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateProject = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (url === "create-project") {
                    navigate(`/create-projects?cid=5&did=6&pid=${res.data}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSubmitKd = (url, data, fetchData, setData, loader, setLoader, setFiles) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (data.type === "wtite") {
                    setData({
                        text: "",
                        title: ""
                    })
                } else if (data.type === "upload") {
                    setData([])
                    setFiles([])
                } else if (data.type === "website") {
                    setData([])
                }
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateKd = (url, data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCheckStatus = (url, data, fetchData, interval) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let status = +res.data[0].status
                if (status === 2 || status === 3) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onCreateConversation = (data, navigate, handleClose, loader, setLoader, fetchData) => (dispatch, getState) => {
    commonAxios("create-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchData) {
                    fetchData()
                } else {
                    navigate(`/chat-screen?id=${res.data}&cid=${data.cid}&did=${data.did}&pid=${data.pid}&type=p`)
                }
                dispatch(setAlert(res.msg, "success"))
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
        })
}


// export const onfetchConversation = (data, loader, setLoader) => (dispatch, getState) => {
//     commonAxios("fetch-conversation", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch({ type: "SET_CONVERSATION", payload: res.data[0] })

//             } else {
//                 dispatch(setAlert(res.msg, "danger"))
//             }
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//         }).catch((err) => {
//             dispatch(setAlert(err.msg, "danger"))
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//             console.log(err)
//         })
// }



// No Axios Here

export const onChangeProject = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_PROJECT", payload: { name, value } })
}

export const unmountCreateProject = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_PROJECT" })
}

export const onActiveConv = (con) => (dispatch) => {
    dispatch({ type: "ACTIVE_CONVERSATION", payload: con })
}

export const defaultActiveConv = (id) => (dispatch) => {
    dispatch({ type: "DEFAULT_CONV_ACTIVE", payload: id })
}
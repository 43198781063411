import React, { useEffect, useState } from "react";
import { RiRobot2Fill } from 'react-icons/ri'
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../Redux/Actions/AuthActions";
import { appName } from "../../../Global/Global";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../CommonComponents/TitleBar";

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/dashboard-1')
        }
    }, [auth])


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(loginUser(loginInfo, setLoader))
    }

    return (
        <>
            <TitleBar title="Login" />
            <div className="login-header"><LoginHeader /></div>
            <div className="login-wrap">
                <div className="login-box">
                    <div className="symbol"><RiRobot2Fill /></div>
                    <h2>Login to {appName}</h2>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="input-wrap">
                            <label htmlFor="">Email Address</label>
                            <div className="inp-outer">
                                <input
                                    className="inp"
                                    type="email"
                                    placeholder="Email Address"
                                    value={loginInfo.email}
                                    onChange={handleChange}
                                    name="email"
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Password</label>
                            <div className="inp-outer">
                                <input
                                    className="inp"
                                    type={view ? "text" : "password"}
                                    placeholder="Password"
                                    required
                                    onChange={handleChange}
                                    value={loginInfo.password}
                                    name="password"
                                />
                                <span
                                    className="inp-icon"
                                    onMouseUp={() => setView(false)}
                                    onMouseLeave={() => setView(false)}
                                    onMouseDown={() => setView(true)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineEyeInvisible />
                                </span>
                            </div>
                        </div>
                        <div className="input-wrap">
                            <button className="theme-btn full" type="submit"><span>Login {loader ? <FaSpinner className="spin ms-1" /> : null}</span></button>
                        </div>
                        {/* <div className="input-wrap">
                            <div className="or"><span>OR</span></div>
                        </div> */}

                        {/* <div className="social-sign">
                            <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/google-logo.svg').default} /></div>
                            <button>Sign up with Google</button>
                        </div>
                        <div className="social-sign">
                            <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/facebook-logo.svg').default} /></div>
                            <button>Sign up with Facebook</button>
                        </div> */}

                    </form>
                </div>

                <div className="log-option">Forgot your account details? <Link to="/forgot-password">Click here</Link></div>
            </div>

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default Login;
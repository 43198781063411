import WebFont from "webfontloader"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import axios from "axios"

export const fetchDataRedux = (url, data, actionType, loader, setLoader, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (isShort) {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                dispatch({ type: actionType, payload: arr })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onFetchById = (url, data, actionType, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: actionType, payload: res.data[0] })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onDeleteData = (url, data, actionType, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: actionType, payload: data.id })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}






export const fetchData = (url, data, setState, loader, setLoader, limit, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (limit) {
                    arr = arr.slice(0, limit)
                }
                if (isShort === "shortDataCt") {
                    arr.sort((a, b) => {
                        if (a.country_name > b.country_name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (isShort === "shortDataLg") {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}


export const fetchDataPrimereact = (url, data, setState, loader, setLoader, sortByName, singleData, dataVal, addAll) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (sortByName) {
                    arr.sort((a, b) => {
                        if (a[sortByName] > b[sortByName]) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (singleData) {
                    arr = arr.map((curElem) => {
                        return curElem[dataVal]
                    })
                    if (addAll) {
                        arr.unshift('All')
                    }
                } else {
                    if (addAll) {
                        arr.unshift({ name: 'All' })
                    }
                }

                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type, handleShow) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    if (handleShow) {
        setLoader({
            ...loader,
            record: true
        })
    } else {
        setLoader({
            ...loader,
            upload: true
        })
    }

    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type, res.data.thumbnail, res.data.localPath)
                    if (handleShow) {
                        handleShow()
                    }
                } else {
                    fetchMedia()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}

export const onDeleteCampaign = (url, data, videos, setVideos, setSweet, fetchFuncn) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchFuncn) {
                    fetchFuncn()
                } else {
                    setVideos(videos.filter((curElem) => {
                        return +curElem.id !== +data.id
                    }))
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onUploadMultipleFiles = (data, handleFunc, loader, setLoader, setPercent, setUploadStatus) => (dispatch, getState) => {

    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }

    commonAxios("upload-files", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                handleFunc(res.data)
                setUploadStatus("Completed")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                upload: false
            })
        })
}

export const onFetchFont = (setFonts, toLoad) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (toLoad) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            } else {
                setFonts(arr);
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';

import '../src/assets/css/dark-theme.css'
import '../src/assets/css/light-theme.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/pages/Authentication/Login';
import Dashboard1 from './components/pages/Dashboard/Dashboard1';
import Dashboard2 from './components/pages/Dashboard/Dashboard2';
import Dashboard3 from './components/pages/Dashboard/Dashboard3';
import Dashboard4 from './components/pages/Dashboard/Dashboard4';
import Dashboard5 from './components/pages/Dashboard/Dashboard5';
import Dashboard6 from './components/pages/Dashboard/Dashboard6';
import Register from './components/pages/Authentication/Register';
import Company from './components/pages/Company/Company';
import CreateCompany from './components/pages/Company/CreateCompany';
import MyAccount from './components/pages/Profile/MyAccount';
import Privacy from './components/pages/Profile/Privacy';
import HelpPost from './components/pages/Profile/HelpPost';
import Staffs from './components/pages/Staffs/Staffs';
import EmbedViewMore from './components/pages/Staffs/EmbedViewMore';
import EmbedCode from './components/pages/Staffs/EmbedCode';
import CreateEmbed from './components/pages/Staffs/CreateEmbed';
import YouAi from './components/pages/YouAi/YouAi';
import CreateCustomAi from './components/pages/YouAi/CreateCustomAi';
import UpdateDirectory from './components/pages/YouAi/DirectoryKnowledge/UpdateDirectory';
import Chat from './components/pages/Chat/Chat';
import ChatScreen from './components/pages/Chat/ChatScreen';
import AutoMode from './components/pages/Chat/AutoMode';
import MasterLogin from './components/pages/Authentication/MasterLogin';
import ResetPassword from './components/pages/Authentication/ResetPassword';
import ForgotPassword from './components/pages/Authentication/ForgotPassword';
import CreateEmbedNew from './components/pages/Staffs/CreateEmbedNew';
import PrivateRoute from './components/CommonComponents/PrivateRoute';
import ManageTeam from './components/pages/Profile/AccountManagement/ManageTeam';
import HelpSupport from './components/pages/Profile/Help/HelpSupport';
import Upgrades from './components/pages/Upgrades/Upgrades';
import HelpDetails from './components/pages/Profile/Help/HelpDetails';
import SupportArticle from './components/pages/Profile/Help/SupportArticle';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Redux/Actions/AuthActions';
import { removeAlert } from './Redux/Actions/AlertActions';
import { onFetchFont } from './Redux/Actions/CommonActions';
import { useEffect } from 'react';
import Alert from './components/CommonComponents/Alert';
import Integrations from './components/pages/Profile/Integrations/Integrations';
import CreateDepartments from './components/pages/Departement/CreateDepartments';
import Departments from './components/pages/Departement/Departments';
import Projects from './components/pages/Projects/Projects';
import CreateProjects from './components/pages/Projects/CreateProjects';
import CompanyProfile from './components/pages/Company/CompanyProfile';
import HireAvailableStuffs from './components/pages/Staffs/HireAvailableStuffs';
import Directory from './components/pages/YouAi/Directory/Directory';
import Conversations from './components/pages/Chat/Conversations/Conversations';
import ConversationFiles from './components/pages/Staffs/ConversationFiles';


function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  const fetchUser = () => {
    dispatch(loadUser())
  }


  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }

  useEffect(() => {
    fetchUser()
    // fetchFonts()
  }, [])

  return (
    <div className="App">
      <Alert />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/master-login" element={<MasterLogin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />


          <Route exact path="/company" element={<PrivateRoute><Company /></PrivateRoute>} />
          <Route exact path="/create-company" element={<PrivateRoute><CreateCompany /> </PrivateRoute>} />
          <Route exact path="/company-profile" element={<PrivateRoute><CompanyProfile /></PrivateRoute>} />
          <Route exact path="/create-departments" element={<PrivateRoute><CreateDepartments /> </PrivateRoute>} />
          <Route exact path="/departments" element={<PrivateRoute><Departments /> </PrivateRoute>} />
          <Route exact path="/create-projects" element={<PrivateRoute><CreateProjects /> </PrivateRoute>} />
          <Route exact path="/projects" element={<PrivateRoute><Projects /> </PrivateRoute>} />

          <Route exact path="/my-account" element={<PrivateRoute><MyAccount /> </PrivateRoute>} />
          <Route exact path="/privacy" element={<PrivateRoute><Privacy /> </PrivateRoute>} />
          <Route exact path="/integrations" element={<PrivateRoute><Integrations /> </PrivateRoute>} />
          <Route exact path="/manage-team" element={<PrivateRoute><ManageTeam /> </PrivateRoute>} />
          <Route exact path="/help-support" element={<PrivateRoute><HelpSupport /> </PrivateRoute>} />
          <Route exact path="/help-details" element={<PrivateRoute><HelpDetails /> </PrivateRoute>} />
          <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /> </PrivateRoute>} />

          <Route exact path="/help-post" element={<PrivateRoute><HelpPost /> </PrivateRoute>} />


          {/* <Route exact path="/staffs" element={<PrivateRoute><Staffs /> </PrivateRoute>} /> */}
          <Route exact path="/embed-more" element={<PrivateRoute><EmbedViewMore /> </PrivateRoute>} />
          <Route exact path="/embed-code" element={<PrivateRoute><EmbedCode /> </PrivateRoute>} />
          <Route exact path="/create-embed" element={<PrivateRoute><CreateEmbed /> </PrivateRoute>} />
          <Route exact path="/create-embed-new" element={<PrivateRoute><CreateEmbedNew /> </PrivateRoute>} />

          <Route exact path="/staffs" element={<PrivateRoute><HireAvailableStuffs /> </PrivateRoute>} />

          <Route exact path="/youai" element={<PrivateRoute><YouAi /> </PrivateRoute>} />
          <Route exact path="/create-custom-ai" element={<PrivateRoute><CreateCustomAi /> </PrivateRoute>} />
          <Route exact path="/directory" element={<PrivateRoute><Directory /> </PrivateRoute>} />
          <Route exact path="/update-directory" element={<PrivateRoute><UpdateDirectory /> </PrivateRoute>} />

          {/* <Route exact path="/chat" element={<PrivateRoute><Chat /> </PrivateRoute>} /> */}
          <Route exact path="/chat-screen" element={<PrivateRoute><ChatScreen /> </PrivateRoute>} />
          <Route exact path="/auto-mode" element={<PrivateRoute><AutoMode /> </PrivateRoute>} />

          <Route exact path="/chat" element={<PrivateRoute><Conversations /> </PrivateRoute>} />

          <Route exact path="/dashboard-1" element={<PrivateRoute><Dashboard1 /> </PrivateRoute>} />
          <Route exact path="/dashboard-2" element={<PrivateRoute><Dashboard2 /> </PrivateRoute>} />
          <Route exact path="/dashboard-3" element={<PrivateRoute><Dashboard3 /> </PrivateRoute>} />
          <Route exact path="/dashboard-4" element={<PrivateRoute><Dashboard4 /> </PrivateRoute>} />
          <Route exact path="/dashboard-5" element={<PrivateRoute><Dashboard5 /> </PrivateRoute>} />
          <Route exact path="/dashboard-6" element={<PrivateRoute><Dashboard6 /> </PrivateRoute>} />


          <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /> </PrivateRoute>} />

          <Route exact path="/conversation-files" element={<PrivateRoute><ConversationFiles /> </PrivateRoute>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useState } from "react";

import EmbedHeader from "../../Header/EmbedHeader";
import Footer from "../../Footer/Footer";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import Select from 'react-select'


function CreateEmbed() {

    const options = [
        { value: 'alphabet', label: 'Alphabet' }
    ]

    const options2 = [
        { value: 'chocolate', label: '(GMT+0:00) UTC' },
        { value: 'strawberry', label: '(GMT+0:00) UTC' },
        { value: 'vanilla', label: '(GMT+0:00) UTC' }
    ]

    return(
        <>
        <EmbedHeader></EmbedHeader>

        <div className="embed-progress">
            <div className="embed-progress-in"></div>
        </div>

        <div className="site-wrapper">
           <div className="site-container">
               <div className="embed-wrap width-600">
                    <div className="titleBar">
                        <div className="titleBar-left">
                            <h2>Create Embed Code</h2>
                            <p>You can customize the working time as well</p>
                        </div>
                    </div>

                    <div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="b" className="navStyle">
                            <Nav.Item>
                            <Nav.Link eventKey="first">
                                <h6>General Settings</h6>
                                <p>Write Basic Information</p>
                            </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second">
                                <h6>Customization</h6>
                                <p>Upload your document</p>
                            </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-5">
                            <Tab.Pane eventKey="first">
                            <div className="input-wrap mt-0">
                                <label htmlFor="">Name</label>
                                <div className="inp-outer">
                                <input className="inp" type="text" placeholder="Enter Embed Name" />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Website URL</label>
                                <div className="inp-outer">
                                <input className="inp" type="text" placeholder="Response Style" />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Autoresponder</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Select Autoresponder</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Autoresponder List</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Select Autoresponder List</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <div className="file-field">
                                    <div className="me-3"><img alt="" src={require('../../../assets/images/icon/img.png')} /></div>
                                    <div>
                                        <h6>Click to Upload Embed Image</h6>
                                        <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                    </div>
                                    <input type="file" />
                                </div>
                            </div>


                            <div className="titleBar mt-5">
                                <div className="titleBar-left">
                                    <h2>Enable Payment</h2>
                                    <p>Decide if you want to charge your client when renting a staff</p>
                                </div>
                                <div>
                                    <div className="switch-btn">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="input-wrap">
                                <label htmlFor="">Payment Plan</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Monthly</option>
                                    </select>
                                </div>
                            </div>

                            <div className="input-wrap">
                                <label htmlFor="">Payment Amount</label>
                                <div className="inp-outer">
                                <input className="inp" type="text" placeholder="2000" />
                                </div>
                            </div>


                            <div className="titleBar mt-5">
                                <div className="titleBar-left">
                                    <h2>Choose Payment Method</h2>
                                    <p>Select your connected payment method to enable payment receive</p>
                                </div>
                            </div>

                            <div className="payment-provider mt-4">
                                <div className="payment-provider-single">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="payment-provider-logo"><img alt="" src={require('../../../assets/images/paypal.svg').default} /></div>
                                                </div>
                                                <div className="col">
                                                    <h6>Paypal</h6>
                                                    <p>For Integrating with other tools</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <button className="theme-btn"><span>Select</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-provider-single">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="payment-provider-logo"><img alt="" src={require('../../../assets/images/stripe.svg').default} /></div>
                                                </div>
                                                <div className="col">
                                                    <h6>Stripe</h6>
                                                    <p>Payment processing through stripe</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <button className="theme-btn"><span>Select</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            <div>


                            <div className="input-wrap mt-0">
                                <label htmlFor="">Theme</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Light Theme</option>
                                    </select>
                                </div>
                            </div>

                            <div className="input-wrap">
                                <label htmlFor="">Brand Color</label>
                                <div className="inp-outer">
                                    <input className="inp" type="text" placeholder="#110000ff" />
                                </div>
                            </div>

                            <div className="input-wrap">
                                <label htmlFor="">Font Name</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Select Font</option>
                                    </select>
                                </div>
                            </div>

                            <div className="input-wrap">
                                <label htmlFor="">Card Size</label>
                                <div className="inp-outer">
                                    <select className="inp" name="" id="">
                                        <option value="">Medium</option>
                                    </select>
                                </div>
                            </div>

                {/* <div className="avl-works pt-0">
                    <div className="avl-works-left">
                        <h6>Available Working Hours</h6>
                    </div>
                    <div className="avl-works-right">
                        <label htmlFor="">Time zone</label>
                        <div className="select-style">
                            <Select options={options2} className="react-select-container" classNamePrefix="react-select" />
                        </div> 
                    </div>
                </div> */}

                {/* <div className="working-hours">
                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Sunday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Monday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Tuesday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Wednesday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Thursday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Friday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="working-hours-single">
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <label htmlFor="" className="ps-2">Saturday</label>
                        </div>
                        <div className="working-hours-right">
                            <div className="form">
                                <span>From</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                            <div className="to">
                                <span>To</span>
                                <span><input className="inp" type="text" placeholder="09:00" /></span>
                                <span>
                                    <select className="inp" name="" id="">
                                        <option value="">AM</option>
                                        <option value="">PM</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}
             </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
               </div>
           </div>
        </div>
          
        <Footer></Footer>

        </>
    )
}

export default CreateEmbed;
import React, { useEffect, useState } from 'react'
import { BsPlusCircleFill, BsImages, BsUpload } from "react-icons/bs"
import { PiMicrophoneBold } from "react-icons/pi"
import { FiDownload, FiSend } from "react-icons/fi"
import { HiDotsVertical } from "react-icons/hi"
import { MdAlternateEmail } from "react-icons/md"
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineClose, AiOutlineEye } from "react-icons/ai"
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import { io } from "socket.io-client";
import { useDispatch, useSelector } from 'react-redux'
import UserMessage from './UserMessage'
import AssistantMessage from './AssistantMessage'
import { useImmer } from 'use-immer'
import StaffToggleBox from './StaffToggleBox'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import { MdOutlineCancel } from "react-icons/md";
import { fetchData } from '../../../../Redux/Actions/CommonActions'


const Messages = ({ id }) => {
    let date = new Date()
    const dispatch = useDispatch()
    const [socket, setSocket] = useState(false)
    const auth = useSelector(state => state.auth)
    const [text, setText] = useState("")
    const [messages, setMessages] = useImmer([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const project = useSelector(state => state.project)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeStaffBox, setActiveStaffBox] = useState(false)
    const [assistant, setAssistant] = useState(false)

    const handleSendMessage = (e) => {
        e.preventDefault()
        if (assistant) {
            let obj = {
                message: text,
                userId: auth.user.id,
                cid: project.activeConv.id,
                staffId: assistant.id,
                assistantId: "asst_rPJkkjCYTyPfBBNFfCviWqaN",
                threadId: "thread_TTx2Srr8cySMFFjlbMdxIrHG",
            }
            setMessages([...messages, {
                type: "user",
                message: text,
                assistantId: "",
                staffId: assistant.id,
                assistant,
                created: date.toLocaleString()
            }
            ])
            setText("")
            socket.emit("send-message", obj)
        } else {
            dispatch(setAlert("Please choose staff to chat with by using '@'", "danger"))
        }
    }

    const handleChange = (e) => {
        let { value } = e.target
        if (!assistant) {
            if (value.indexOf('@') === 0) {
                setActiveStaffBox(true)
            } else {
                setActiveStaffBox(false)
            }
        }
        setText(value)
    }

    const fetchMessages = () => {
        let data = {
            conversationId: project.activeConv.id
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-messages", data, setMessages, loader, setLoader))
    }


    useEffect(() => {
        if (socket) {
            const handleReceivedResponse = (res) => {
                switch (res.status) {
                    case "start":
                        let obj = {
                            type: "assiatant",
                            message: "",
                            assistant,
                            staffId: assistant.id,
                            created: date.toLocaleString()
                        }
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            const lastIndex = updatedMessages.length;
                            if (lastIndex >= 0) {
                                updatedMessages[lastIndex] = obj
                            }
                            return updatedMessages;
                        });
                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [assistant])

    useEffect(() => {
        if (socket) {
            socket.emit("new-user-joined", {
                assistantId: "asst_rPJkkjCYTyPfBBNFfCviWqaN",
                threadId: "thread_TTx2Srr8cySMFFjlbMdxIrHG",
            })

            return () => {
                socket.off('new-user-joined');
            };
        }
    }, [socket])

    useEffect(() => {
        if (project.activeConv) {
            fetchMessages()
            if (project.activeConv.type === "single") {
                setAssistant(project.activeConv.staff[0])
            }
        }
    }, [project.activeConv])


    useEffect(() => {
        const so = io("http://54.83.99.135:6002", { transports: ['websocket'] });
        setSocket(so)
    }, [])


    return (

        <div className="site-wrapper forChat">
            {loader.fetch ?
                <div className="loader-sec">
                    <div className="loader" />
                </div>
                :
                <div className="chat-container">
                    <div className="messages">
                        {messages.length > 0 ?
                            messages.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            curElem.type === "user" ?
                                                <UserMessage
                                                    curElem={curElem}
                                                    socket={socket}
                                                    activeConv={project.activeConv}
                                                />
                                                :
                                                <AssistantMessage
                                                    curElem={curElem}
                                                    socket={socket}
                                                    messages={messages}
                                                    setMessages={setMessages}
                                                    index={index}
                                                    assistant={assistant}
                                                    activeConv={project.activeConv}
                                                />

                                        }
                                    </React.Fragment>
                                )
                            })
                            : ""}




                        {/* <div className="message-wrap">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-9.png')} /></div>
                                <div className="message-head">
                                    <span>Alexandra Wills</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="message-wrap">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-9.png')} /></div>
                                <div className="message-head">
                                    <span>Alexandra Wills</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        <div className="message-images">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-1.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-2.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-3.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="message-image-single">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-4.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="message-wrap own-message">
                        <div className="message-single">
                            <div className="message-group">
                                <div className="user-photo"><img alt="" src={require('../../../../assets/images/user-8.png')} /></div>
                                <div className="message-head">
                                    <span>You</span>
                                    <span>09:00 AM</span>
                                </div>
                                <div className="message-body">
                                    <div className="message-txt">
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        <div className="message-images">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="message-image-single img-full">
                                                        <img alt="" src={require('../../../../assets/images/chat-img-1.png')} />
                                                        <div className="message-image-opt">
                                                            <div className="embed-user-icon"><AiOutlineEye /></div>
                                                            <div className="embed-user-icon"><FiDownload /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-nav">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="g" id="dropdown-basic">
                                                <div className="drop-icon"><HiDotsVertical /></div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <ul>
                                                    <li><Link to="/">Action</Link></li>
                                                    <li><Link to="/">Another action</Link></li>
                                                    <li><Link to="/">Something else</Link></li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="msg-tags">Tags <span>@Alexandra</span> <span><BsImages /> Image</span></div>
                            </div>
                        </div>
                    </div> */}

                    </div>
                </div>
            }
            <div className="chatscreen-bottom">
                <div className="chat-container">
                    <form className="inp-outer icon-left" onSubmit={handleSendMessage} style={{ position: "relative" }}>
                        {assistant ?
                            <div style={{ height: "100%" }} className='d-flex align-items-center mentionTag'>
                                <span className='added-staff-batch'>{assistant.name} <span className='ms-2'>
                                    {project.activeConv.type === "group" ?
                                        <MdOutlineCancel style={{ cursor: "pointer" }} size={20} onClick={() => setAssistant(false)} /> : ""}
                                </span></span>
                            </div>
                            : ""}

                        <input
                            type="text"
                            className="inp alt"
                            placeholder="Type new questions"
                            value={text}
                            onChange={handleChange}
                            required
                        />
                        {activeStaffBox ?
                            <StaffToggleBox
                                text={text}
                                setActiveStaffBox={setActiveStaffBox}
                                setAssistant={setAssistant}
                                setText={setText}
                            />
                            : ""}
                        <span className="inp-icon">
                            <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="g" id="dropdown-basic">
                                        <div className="drop-icon"><BsPlusCircleFill /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="act-menu">
                                            <div className="embed-user-icon"><BsImages /></div>
                                            <div className="act-menu-txt">
                                                <h6>Image Prompt</h6>
                                                <p>Generate image reply</p>
                                            </div>
                                        </div>
                                        <div className="act-menu">
                                            <div className="embed-user-icon"><MdAlternateEmail /></div>
                                            <div className="act-menu-txt">
                                                <h6>Mention Staff</h6>
                                                <p>Mention Staff for reply</p>
                                            </div>
                                        </div>
                                        <div className="act-menu">
                                            <div className="embed-user-icon"><BsUpload /></div>
                                            <div className="act-menu-txt">
                                                <h6>Upload Image</h6>
                                                <p>Input image in prompt</p>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </span>

                        <div className="chat-icon">
                            <span><PiMicrophoneBold /></span>
                            <button type='submit' style={{ border: "none", background: "transparent" }}><span><FiSend /></span></button>
                        </div>
                    </form>
                    <p className="chat-info">You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                </div>
            </div>

            <Modal className="VideoModal modal-700 no-bg" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div>
                        <div className="closePrev"><span onClick={handleClose}>Close Preview <AiOutlineClose /></span></div>
                        <p>Image 2</p>
                        <p>Dog driving on Bike, Realistic Image, Digital art, wearing helmet, Clean image </p>
                        <div className="modal-img"><img alt="" src={require('../../../../assets/images/modal-img.jpg')} /></div>
                        <div className="modal-img-download">
                            <button className="theme-btn grey"><span><FiDownload /> Download Image</span></button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Messages
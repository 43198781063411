import React, { useState } from "react";

import BackHeader from "../../Header/BackHeader";
import Footer from "../../Footer/Footer";

import{BiTrash, BiSolidCopy} from "react-icons/bi"
import{ BsCardImage, BsGlobeEuropeAfrica} from "react-icons/bs"
import{ AiOutlineClose} from "react-icons/ai"

import { Modal } from "react-bootstrap";


function EmbedCode() {

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return(
        <>
        <BackHeader></BackHeader>

        <div className="site-wrapper">
           <div className="site-container">
               <div className="embed-wrap">
                  <div className="embed-block mt-0">
                    <div className="embed-block-top">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="d-flex align-items-center">
                           <span className="active-tag">Active</span> 
                          <div className="embed-user-icon ms-2" onClick={handleShow2}><BiTrash/></div>
                        </div>
                    </div>

                    <h6 className="pt-4">Test Embed Name</h6>
                    <div className="embed-meta">
                        <div><span className="embed-meta-txt">15-09-2023 - 09:30 AM</span></div>
                        <div><span className="embed-meta-img"><BsGlobeEuropeAfrica/></span> <span className="embed-meta-txt">app.aistaffs.com</span></div>
                        <div><span className="embed-meta-img"><img alt="" src={require('../../../assets/images/staff-5.jpg')} /></span> <span className="embed-meta-txt">Johnson Doe</span></div>
                    </div>
                  </div>

                  <div className="embed-block">
                    <div className="embed-block-top">
                        <div className="embed-block-top-left">
                            <h2>Header Code</h2>
                            <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="embed-user-icon ms-2"><BiSolidCopy/></div>
                        </div>
                    </div>
                    <div className="code-block">
                        <p>&#x3C;script type=&#x22;text/Javascript&#x22; id=&#x22;ai_widget&#x22; src=&#x22;https://backend.aistaffs.com/front-end/chat-box/embed.js type=aiStaff&#x26;staffId=26&#x26;uId=3180&#x26;arId=0&#x26;arListId=0&#x26;icn=https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png&#x26;webUrl=https://google.com&#x26;embId=158&#x22;&#x3E;&#x3C;/script&#x3E; </p>
                    </div>
                  </div>

                  <div className="embed-block">
                    <div className="embed-block-top">
                        <div className="embed-block-top-left">
                            <h2>Body Code</h2>
                            <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="embed-user-icon ms-2"><BiSolidCopy/></div>
                        </div>
                    </div>
                    <div className="code-block">
                        <p>&#x3C;div id = &#x22;aistaff_chat_box&#x22;&#x3E;&#x3C;/div &#x3E; </p>
                    </div>
                  </div>

               </div>
           </div>
        </div>
          
        <Footer></Footer>

        {/* Add Member Modal */}
       <Modal className="VideoModal sm-modal" show={show2} onHide={handleClose2} centered>
            <Modal.Body>
            <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose/></span>
             <div className="">
                <div className="text-center pt-4">
                  <div className="modalIcon"><BsCardImage/></div>

                  <div className="confirmation-cont">
                    <h5 className="pt-4">Are you sure to delete this “Johnson Doe - Embed AI Site” Embed ?</h5>
                    <p>You can’t Restore the deleted Embed data</p>
                  </div>

                  <div className="btnGroup mt-4 mb-4">
                    {/* <button className="theme-btn bdr" onClick={handleClose2}><span>Cancel</span></button> */}
                    <button className="theme-btn"><span><BiTrash/> Delete Embed</span></button>
                  </div>
                </div>
             </div>
            </Modal.Body>
        </Modal>
       {/* Add Member Modal */}

        </>
    )
}

export default EmbedCode;
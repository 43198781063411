import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi"
import { AiOutlineMenu } from "react-icons/ai"
import UserNav from "../CommonComponents/UserNav";
import queryString from "query-string";

const BackHeader = () => {
    const location = useLocation()
    const { cid } = queryString.parse(location.search)
    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="backBtn">
                            <Link className="back" to={`/departments?cid=${cid}`}><BiChevronLeft />Back</Link>
                        </div>
                        <div className="header-right">
                            <div className="profile-link">
                                <UserNav />
                            </div>

                            <div className="mobMenu"><AiOutlineMenu /></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default BackHeader;
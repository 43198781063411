import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import { BiDotsHorizontalRounded, BiSolidBrush } from "react-icons/bi"
import { AiFillFilter } from "react-icons/ai"
import { MdArrowBackIos } from "react-icons/md"
import { FaClockRotateLeft, FaUser, FaUserPlus } from "react-icons/fa6"
import { BsFillChatDotsFill, BsStars, BsBoxArrowInLeft } from "react-icons/bs"
import { HiDotsHorizontal } from "react-icons/hi"
import { AiOutlineClose } from "react-icons/ai"
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import CreateConvModal from "../../Projects/CreateConvModal";
import queryString from "query-string";
import ConversationRows from "./ConversationRows";

const ChatHeader = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { pid, cid, did } = queryString.parse(location.search)
    const project = useSelector(state => state.project)
    const [isActive, setActive] = useState("false");

    const [language, setLanguage] = useState([])
    const [response, setResponse] = useState([])
    const [tone, setTone] = useState([])

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const ToggleClass = () => {
        setActive(!isActive);
    };


    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-languages", data, setLanguage, false, false, false, "shortDataLg"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchData("fetch-tone", data, setTone, false, false, false, "shortDataLg"))
    }

    const fetchResponse = () => {
        dispatch(fetchData("fetch-response-style", {}, setResponse, false, false, false, "shortDataLg"))
    }

    console.log(project.activeConv)

    useEffect(() => {
        fetchLanguage()
        fetchTone()
        fetchResponse()
    }, [])

    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="chat-header-left" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                            <div className="icon-back" ><MdArrowBackIos /></div>
                            <div className="user-stack">
                                {project.activeConv.staff.length > 0 ?
                                    project.activeConv.staff.map((curElem, index) => {
                                        return (
                                            <div className="user-stack-single" key={index}>
                                                <img alt="" src={curElem.image} />
                                            </div>
                                        )
                                    })
                                    : ""}
                                {project.activeConv.type === "group" ?
                                    <div className="user-stack-single last"><span>{project.activeConv.staff.length > 4 ? "4+" : project.activeConv.staff.length}</span></div> : null}
                            </div>
                            <h6>{project.create.name}</h6>
                        </div>

                        <div className={isActive ? "chat-header-right" : "chat-header-right act"}>
                            <span className="chatheader-close" onClick={ToggleClass}><AiOutlineClose /></span>
                            <div className="chat-mode"><BsStars /> God Mode</div>
                            <div className="user-nav history">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="drop-icon"><FaClockRotateLeft /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button className="theme-btn full mb-3" onClick={handleShow}><BsFillChatDotsFill /> <span>New Conversation</span></button>
                                        <ul>
                                            {project.conversationList.length > 0 ?
                                                project.conversationList.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ConversationRows
                                                                curElem={curElem}
                                                                activeConv={project.activeConv}
                                                            />
                                                        </React.Fragment>

                                                    )
                                                })
                                                : ""}
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {project.activeConv.type === "group" ?
                                <div className="user-nav history">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="n" id="dropdown-basic">
                                            <div title="Staffs" className="drop-icon"><FaUser /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button className="theme-btn full mb-3"><FaUserPlus /> <span>Add Staffs</span></button>
                                            <h6 className="pb-3">Added Staffs <span>({project.create.staff.length})</span></h6>
                                            <ul>
                                                {project.create.staff.length > 0 ?
                                                    project.create.staff.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className="userList">
                                                                    <div className="userList-left">
                                                                        <div className="userList-img"><img alt="" src={curElem.image} /></div>
                                                                        <div className="userList-txt">
                                                                            <h5>{curElem.name}</h5>
                                                                            <p><FaUser /> {curElem.category} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="drop-style">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="t" id="dropdown-basic">
                                                                                <BiDotsHorizontalRounded />
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <ul>
                                                                                    <li><Link to="/">Action</Link></li>
                                                                                    <li><Link to="/">Another action</Link></li>
                                                                                    <li><Link to="/">Something else</Link></li>
                                                                                </ul>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })

                                                    : ""}
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> : ""}

                            <div className="user-nav history">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="drop-icon"><AiFillFilter /></div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <h5>Chat Filter</h5>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select name="" id="" className="inp alt">
                                                    <option value="">Language</option>
                                                    {language.length > 0 ?
                                                        language.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select name="" id="" className="inp alt">
                                                    <option value="">Tone</option>
                                                    {tone.length > 0 ?
                                                        tone.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-wrap">
                                            <div className="inp-outer">
                                                <select name="" id="" className="inp alt">
                                                    <option value="">Response Type</option>
                                                    {response.length > 0 ?
                                                        response.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="user-nav forClear">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="drop-icon"><HiDotsHorizontal /></div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/"><BiSolidBrush /> Clear all Chat</Link></li>
                                            <li><Link to="/"><BsBoxArrowInLeft /> Exit Chat</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>

                        <div className="drop-icon toggle" onClick={ToggleClass}><HiDotsHorizontal /></div>

                    </div>
                </div>
            </header>

            <CreateConvModal
                show={show}
                pid={pid}
                cid={cid}
                did={did}
                fetchConv={props.fetchConversation}
                handleClose={handleClose}
            />
        </>
    )
}

export default ChatHeader;
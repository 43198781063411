import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { BiSearch, BiDotsHorizontalRounded, BiTrash, BiFolderPlus } from "react-icons/bi"
import { AiFillClockCircle } from "react-icons/ai"
import { BsCodeSlash, BsFillChatDotsFill, BsFire, BsCardImage, BsFillPlusCircleFill } from "react-icons/bs"
import { MdEdit, MdOutlineRefresh } from "react-icons/md"
import { RiRobot2Line } from "react-icons/ri"
import { AiOutlineEyeInvisible, AiOutlineClose } from "react-icons/ai"
import Select from 'react-select'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";
import StaffRows from "../Staffs/StaffRows";
import Directory from "./Directory/Directory";


const YouAi = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const staff = useSelector(state => state.staff)
    const [pageType, setPageType] = useState("customAi")
    const [customStaff, setCustomStaff] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const [filterObj, setFilterObj] = useState({
        q: ""
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }

    const handleCreateButton = () => {
        if (pageType === "customAi") {
            navigate("/create-custom-ai")
        } else {
            handleShow()
        }
    }

    const fetchCustomStaffs = () => {
        dispatch(fetchDataRedux("fetch-hired-staff", {}, "FETCH_CUSTOMESTAFF_LIST", loader, setLoader, true))
    }

    useEffect(() => {
        let arr = []
        arr = staff.customList.filter((curElem) => {
            return curElem.type === "youAiStaff"
        })
        arr = arr.filter((curElem) => {
            return curElem.name.toLowerCase().includes(filterObj.q.toLowerCase())
        })
        setCustomStaff(arr)
    }, [staff.customList, filterObj])

    useEffect(() => {
        fetchCustomStaffs()
    }, [])


    return (
        <>
            <TitleBar title="YouAI" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container">
                        <div className="titleBar forStaff">
                            <div className="titleBar-left">
                                <h2>YouAi</h2>
                                <p>Create you Ai and Upload Directories</p>
                            </div>
                            <div className="titleBar-right">
                                <div className="input-wrap alt mt-0">
                                    <div className="inp-outer icon-left">
                                        <input
                                            className="inp"
                                            type="text"
                                            placeholder="Search"
                                            value={filterObj.q}
                                            onChange={(e) => handleChange("q", e.target.value)}
                                        />
                                        <span className="inp-icon"><BiSearch /></span>
                                    </div>
                                </div>
                                <div className="staffBtn">
                                    <button className={pageType === "customAi" ? "active" : ""} onClick={() => setPageType("customAi")}><span>Custom Ai</span></button>
                                    <button className={pageType === "dir" ? "active" : ""} onClick={() => setPageType("dir")}><span>Directory</span></button>
                                </div>
                                <div className="create-btn">
                                    <div className="theme-btn sm-w-100" onClick={handleCreateButton}>
                                        <span><BsFillPlusCircleFill /> Create</span>
                                    </div>
                                    {/* <Dropdown>
                                        <Dropdown.Toggle variant="b" id="dropdown-basic" className="theme-btn sm-w-100">
                                            <span><BsFillPlusCircleFill /> Create</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li>
                                                    <Link to="/create-custom-ai">
                                                        <div className="create-icon"><RiRobot2Line /></div>
                                                        <div>
                                                            <h6>Create Custom Ai</h6>
                                                            <p>Make Own Ai Staff</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/create-directory">
                                                        <div className="create-icon"><BiFolderPlus /></div>
                                                        <div>
                                                            <h6>Create Directory</h6>
                                                            <p>Upload Ai Directory</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="outer-container">
                        <div className="staff-list">
                            {pageType === "customAi" ?
                                <div className="row">
                                    {customStaff.length > 0 ?
                                        customStaff.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <StaffRows
                                                        curElem={curElem}
                                                        fetchStaffs={fetchCustomStaffs}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className="text-center mt-4">
                                            {loader.fetch ?
                                                <FaSpinner className="spin" size={25} /> :
                                                "No custom staff available"
                                            }
                                        </div>
                                    }
                                </div>
                                :
                                <Directory
                                    show={show}
                                    handleClose={handleClose}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default YouAi;
import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import queryString from "query-string";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../CommonComponents/TitleBar";
import DepartmentRows from "./DepartmentRows";
import { unmountDepartmentList } from "../../../Redux/Actions/DepartmentActions";

const Departments = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { cid } = queryString.parse(location.search)
    const department = useSelector(state => state.department)
    const [q, setQ] = useState("")
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchDepartment = () => {
        let data = {
            id: cid
        }
        // let url = "fetch-department-all"
        // if (cid) {
        let url = "fetch-department-list"
        // }
        dispatch(fetchDataRedux(url, data, "FETCH_DEPARTMENT_LIST", loader, setLoader))
    }

    useEffect(() => {
        if (cid) {
            fetchDepartment()
        }

        return () => {
            dispatch(unmountDepartmentList())
        }
    }, [cid])

    return (
        <>
            <TitleBar title="Departments" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="department-list">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>My Departments</h2>
                                <p>Create and Manage Departs</p>
                            </div>
                            <div className="titleBar-right">
                                <div className="input-wrap">
                                    <div className="inp-outer icon-left">
                                        <input
                                            className="inp"
                                            type="text"
                                            placeholder="Search"
                                            onChange={(e) => setQ(e.target.value)}
                                        />
                                        <span className="inp-icon"><BiSearch /></span>
                                    </div>
                                </div>
                                <Link className="theme-btn sm-w-100" to={`/create-departments?cid=${cid}`}><span><AiFillPlusCircle /> Create Department</span></Link>
                            </div>
                        </div>
                    </div>

                    <div className="department-list">
                        <div className="row">
                            {department.list.length > 0 ?
                                department.list.filter((cur) => {
                                    return cur.name.toLowerCase().includes(q.toLowerCase())
                                }).length > 0 ?
                                    department.list.filter((cur) => {
                                        return cur.name.toLowerCase().includes(q.toLowerCase())
                                    }).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <DepartmentRows
                                                    curElem={curElem}
                                                />
                                            </React.Fragment>
                                        )
                                    }) :
                                    <div className="col-12 text-center mt-4">
                                        No department found.
                                    </div>
                                :
                                <div className="col-12 text-center mt-4">
                                    {loader.fetch ?
                                        <FaSpinner className="spin" size={25} />
                                        : "You do not have any department in this company."}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Departments;
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFillGridFill, BsFillLayersFill, BsChatDotsFill } from "react-icons/bs"
import { TiUser } from "react-icons/ti"
import { RiRobot2Fill } from "react-icons/ri"
import { AiOutlineMenu } from "react-icons/ai"
import UserNav from "../CommonComponents/UserNav";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../Redux/Actions/CommonActions";
import { FaCirclePlus } from "react-icons/fa6";
import queryString from "query-string";
import { FaEye } from "react-icons/fa";

const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { cid } = queryString.parse(location.search)
    const company = useSelector(state => state.company.list)
    const [selectedCompnay, setSeletcedCompany] = useState({})

    const fetchCompany = () => {
        dispatch(fetchDataRedux("fetch-company-list", {}, "FETCH_COMPANY_LIST", false, false))
    }

    useEffect(() => {
        if (cid) {
            let data = company.find(({ id }) => +id === +cid)
            if (data) {
                setSeletcedCompany(data)
            }
        }
        else {
            if (company.length > 0) {
                setSeletcedCompany(company[0])
            }
        }
    }, [cid, company])

    useEffect(() => {
        fetchCompany()
    }, [])

    return (
        <>
            <header className="site-header">
                <div className="site-container">
                    <div className="site-header-main">
                        <div className="logo"><img alt="" src={require('../../assets/images/logo.svg').default} /></div>
                        <div className="header-right">
                            <div className="site-nav">
                                <ul>
                                    <li><NavLink to="/dashboard-1"><span><BsFillGridFill /></span> Dashboard</NavLink></li>
                                    <li><NavLink to={`/departments?cid=${selectedCompnay.id ? selectedCompnay.id : 0}`}><span><BsFillLayersFill /></span> Departments</NavLink></li>
                                    <li><NavLink to="/staffs"><span><TiUser /></span> Staffs</NavLink></li>
                                    <li><NavLink to="/chat"><span><BsChatDotsFill /></span> Chat</NavLink></li>
                                    <li><NavLink to="/youai"><span><RiRobot2Fill /></span> You AI</NavLink></li>
                                </ul>
                            </div>
                            <div className="profile-link">
                                <div className="company-link">
                                    <div className="company-link-in">
                                        {company.length > 0 ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="n" id="dropdown-basic">
                                                    <span className="company-logo"><img alt="" src={require('../../assets/images/settings.png')} /></span>
                                                    <span className="company-name">{selectedCompnay.name}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <ul>
                                                        <li><Link to={`/create-company`}><FaCirclePlus size={16} /> <span>Create New Company</span></Link></li>
                                                        {company.slice(0, 5).map((curElem, index) => {
                                                            return (
                                                                <li key={index}><Link to={`/departments?cid=${curElem.id}`}><img alt="" src={curElem.image} /> <span>{curElem.name}</span></Link></li>
                                                            )
                                                        })}
                                                        <li><Link to={`/company`}><FaEye size={16} />  <span>View All</span></Link></li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            <button type="button" id="dropdown-basic" aria-expanded="false" class="dropdown-toggle btn btn-n">
                                                <Link to={'/create-company'}> <span class="company-name">Create Company</span></Link>
                                            </button>
                                        }
                                    </div>
                                </div>

                                <UserNav />
                            </div>

                            <div className="mobMenu"><AiOutlineMenu /></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
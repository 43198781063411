import React, { useEffect, useState } from "react";
import { BiDotsHorizontalRounded, BiFolderPlus } from "react-icons/bi"
import { BsFillCloudArrowUpFill, BsLink45Deg } from "react-icons/bs"
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import CreateDirectoryModal from "./CreateDirectoryModal";
import { fetchDataRedux, onDeleteData } from "../../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import CommonAlert from "../../../CommonComponents/CommonAlert";
import DirectoryRows from "./DirectoryRows";

const Directory = ({ show, handleClose }) => {

    const dispatch = useDispatch()
    const directory = useSelector(state => state.directory)

    const [dirShow, setDirShow] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchDirectoryList = () => {
        dispatch(fetchDataRedux("fetch-directory-list", {}, "FETCH_DIRECTORY_LIST", loader, setLoader, true))
    }

    useEffect(() => {
        setDirShow(directory.list)
    }, [directory])

    useEffect(() => {
        fetchDirectoryList()
    }, [])
    return (
        <div className="row">
            {dirShow.length > 0 ?
                dirShow.map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <DirectoryRows
                                curElem={curElem}
                            />
                        </React.Fragment>
                    )
                })
                :
                <div className="text-center mt-4">
                    {loader.fetch ?
                        <FaSpinner className="spin" size={25} /> :
                        "No directory available"
                    }
                </div>
            }

            <CreateDirectoryModal
                show={show}
                handleClose={handleClose}
            />
        </div>
    )
}

export default Directory;
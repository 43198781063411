import React, { useState } from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Modal } from "react-bootstrap";

import{FaPlay} from "react-icons/fa"
import{BsCheck, BsFillLayersFill, BsFillChatDotsFill, BsLaptop, BsFacebook} from "react-icons/bs"
import{RiUser3Fill} from "react-icons/ri"
import{FiChevronRight} from "react-icons/fi"
import{BiDotsHorizontalRounded, BiLaptop} from "react-icons/bi"

import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import Select from 'react-select'

function Dashboard6() {

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const percentage = 66;

    return(
        <>
        <div className="var-6"><Header></Header></div>

        <div className="site-wrapper">
           <div className="site-container">
              <div className="hero-banner hero-banner-2 hero-banner-6">
                <div className="hero-banner-left">
                    <h1>Welcome Johnson</h1>
                    <p>You can manage your Company and  Staffs Here</p>
                </div>
                <div className="hero-banner-right">
                <div className="company-link">
                    <div className="company-link-in">
                        <Dropdown>
                            <Dropdown.Toggle variant="n" id="dropdown-basic">
                                <span className="company-logo"><img alt="" src={require('../../../assets/images/settings.png')} /></span>
                                <span className="company-name">Company 1</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    <li><Link to="/"><img alt="" src={require('../../../assets/images/design.png')} /> <span>Design Design Competition</span></Link></li>
                                    <li><Link to="/"><img alt="" src={require('../../../assets/images/settings.png')} /> <span>Settings</span></Link></li>
                                    <li><Link to="/"><img alt="" src={require('../../../assets/images/intregration.png')} /> <span>Integrations</span></Link></li>
                                    <li><Link to="/"><img alt="" src={require('../../../assets/images/manage.png')} /> <span>Manage Team</span></Link></li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                </div>

                <div className="hero-banner-video">
                   <video autoPlay loop muted style={{ width: "100%" }} src="https://reeelapps-app.s3.us-west-2.amazonaws.com/AISTAFF_BGVIDEO.mp4"/>
                </div>
              </div>

              <div className="dashboard-wrap dashboard-wrap-v6">
                <div className="dashboard-left">
                    <div className="dashboard-block profile-stat">
                       <div className="profile-stat-top">
                            <div className="circlePro">
                            <div className="circlePro-img"><img alt="" src={require('../../../assets/images/user.jpg')} /></div>    
                            <CircularProgressbar value={percentage} 
                            styles={buildStyles({
                                strokeWidth: 100,
                                circleRatio: 1,
                            })}
                            />
                            </div>
                            <div className="profile-stat-details">
                                <h5>Johnson Doe</h5>
                                <p>60% Profile Completed</p>
                            </div>
                       </div> 
                       <div className="profile-stat-list">
                        <ul>
                            <li className="active"><span><BsCheck/></span> Conversation with AI</li>
                            <li><span><BsCheck/></span> Create Company</li>
                            <li><span><BsCheck/></span> Hire AI Staffs to Company</li>
                            <li><span><BsCheck/></span> Create Your Own AI</li>
                        </ul>
                       </div> 
                    </div>
                    <div className="dashboard-block credits">
                        <h2 className="title">Credits</h2>
                        <div className="credit-list">
                            <ul>
                                <li>
                                    <div className="credit-icon"><img alt="" src={require('../../../assets/images/icon/chat.svg').default} /></div>
                                    <div className="credit-details">
                                        <h3>5000 <span>Left</span></h3>
                                        <p>Conversation Credits</p>
                                        <div className="credit-progress">
                                            <div className="credit-progress-bar" style={{width:'85%'}}></div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="credit-icon"><img alt="" src={require('../../../assets/images/icon/image.svg').default} /></div>
                                    <div className="credit-details">
                                        <h3>230 <span>Left</span></h3>
                                        <p>Media Credits</p>
                                        <div className="credit-progress">
                                            <div className="credit-progress-bar" style={{width:'45%'}}></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="dashboard-block tutorial">
                       <h2 className="title">Tutorials</h2>
                       <div className="tutorial-list">
                        <ul>
                            <li>
                               <Link to="/">
                                <div className="tutorial-icon"><FaPlay/></div> 
                                <div className="tutorial-details">
                                    <h5>How to Hire Staff</h5>
                                    <p>01:20</p>
                                </div>
                               </Link> 
                            </li>
                            <li>
                               <Link to="/">
                                <div className="tutorial-icon"><FaPlay/></div> 
                                <div className="tutorial-details">
                                    <h5>How to Buy Credits and Contents</h5>
                                    <p>01:20</p>
                                </div>
                               </Link> 
                            </li>
                            <li>
                               <Link to="/">
                                <div className="tutorial-icon"><FaPlay/></div> 
                                <div className="tutorial-details">
                                    <h5>Write Great Contents</h5>
                                    <p>01:20</p>
                                </div>
                               </Link> 
                            </li>
                            <li>
                               <Link to="/">
                                <div className="tutorial-icon"><FaPlay/></div> 
                                <div className="tutorial-details">
                                    <h5>How to Train AI Staff</h5>
                                    <p>01:20</p>
                                </div>
                               </Link> 
                            </li>
                            <li>
                               <Link to="/">
                                <div className="tutorial-icon"><FaPlay/></div> 
                                <div className="tutorial-details">
                                    <h5>How to Train AI Staff</h5>
                                    <p>01:20</p>
                                </div>
                               </Link> 
                            </li>
                        </ul>
                       </div>
                    </div>
                </div>
                <div className="dashboard-right">
                    <div className="row dash-row">
                        <div className="col-lg-4">
                            <div className="dashboard-block credits">
                                <div className="credits-item">
                                    <div className="credits-icon"><BsFillLayersFill/></div>
                                    <div className="credits-details">
                                        <h2>14</h2>
                                        <p>Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="dashboard-block credits">
                                <div className="credits-item">
                                    <div className="credits-icon"><RiUser3Fill/></div>
                                    <div className="credits-details">
                                        <h2>156</h2>
                                        <p>Staffs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="dashboard-block credits">
                                <div className="credits-item">
                                    <div className="credits-icon"><BsFillChatDotsFill/></div>
                                    <div className="credits-details">
                                        <h2>234</h2>
                                        <p>Conversation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row dash-row">
                        <div className="col-lg-7">
                            <div className="dashboard-block recent-conv">
                                <h2>Recent Conversations</h2>
                                <div className="recent-conv-list">
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={require('../../../assets/images/user-2.jpg')} />
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Jessica Williamson</h5>
                                                        <p>Hey, Here is answer for your question</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={require('../../../assets/images/user-3.jpg')} />
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Jessica Williamson</h5>
                                                        <p>Hey, Here is answer for your question Hey, Here is answer for your question Hey, Here is answer for your question</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={require('../../../assets/images/user-4.jpg')} />
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Jessica Williamson</h5>
                                                        <p>Hey, Here is answer for your question</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <img alt="" src={require('../../../assets/images/user-5.jpg')} />
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Jessica Williamson</h5>
                                                        <p>Hey, Here is answer for your question</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="dashboard-block departments">
                                <h2>Departments</h2>
                                <div className="recent-conv-list">
                                    <ul>
                                        <li>
                                            <Link to="">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <BsFillLayersFill/>
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Development Department</h5>
                                                        <p>30 Members</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <BsFillLayersFill/>
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Design Department</h5>
                                                        <p>30 Members</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <div className="recent-conv-left">
                                                    <div className="recent-conv-icon">
                                                        <BsFillLayersFill/>
                                                        <div className="user-symbol"><BsFillChatDotsFill/></div>
                                                    </div>
                                                    <div className="recent-conv-details">
                                                        <h5>Backend Department</h5>
                                                        <p>30 Members</p>
                                                    </div>
                                                </div>
                                                <div className="recent-conv-right">
                                                    <div className="next-icon"><FiChevronRight/></div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-block useage">
                        <div className="useage-top">
                            <div className="useage-top-left">
                                <h2>AIStaff Usage</h2>
                                <p>Check Staffs Performance and Usage</p>
                            </div>
                            <div className="useage-top-right">
                              <div className="select-style">
                                 <Select options={options} className="react-select-container" classNamePrefix="react-select" />
                              </div>  
                            </div>
                        </div>
                        <div className="useage-data">
                            <div className="useage-data-single">
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={require('../../../assets/images/user-6.png')} /></div>
                                    <div className="useage-data-cont">
                                        <h5>Jessica Williamson</h5>
                                        <p><RiUser3Fill/> Content Writer</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>300 <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>70 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="useage-data-single">
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={require('../../../assets/images/user-7.png')} /></div>
                                    <div className="useage-data-cont">
                                        <h5>Jessica Williamson</h5>
                                        <p><RiUser3Fill/> Content Writer</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>300 <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>70 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="useage-data-single">
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={require('../../../assets/images/user-8.png')} /></div>
                                    <div className="useage-data-cont">
                                        <h5>Jessica Williamson</h5>
                                        <p><RiUser3Fill/> Content Writer</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>300 <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>70 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="useage-data-single">
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={require('../../../assets/images/user-9.png')} /></div>
                                    <div className="useage-data-cont">
                                        <h5>Jessica Williamson</h5>
                                        <p><RiUser3Fill/> Content Writer</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>300 <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>70 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="useage-data-single">
                                <div className="useage-data-left">
                                    <div className="useage-data-img"><img alt="" src={require('../../../assets/images/user-10.png')} /></div>
                                    <div className="useage-data-cont">
                                        <h5>Jessica Williamson</h5>
                                        <p><RiUser3Fill/> Content Writer</p>
                                    </div>
                                </div>
                                <div className="useage-data-right">
                                    <div>
                                        <h5>300 <span>Used</span></h5>
                                        <p>Conversation Credits</p>
                                    </div>
                                    <div>
                                        <h5>70 <span>Used</span></h5>
                                        <p>Media Credits</p>
                                    </div>
                                    <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="t" id="dropdown-basic">
                                            <BiDotsHorizontalRounded/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li><Link to="/">Action</Link></li>
                                                <li><Link to="/">Another action</Link></li>
                                                <li><Link to="/">Something else</Link></li>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row dash-row">
                        <div className="col-lg-6">
                            <div className="dashboard-block webnair">
                                <h2>Check Our Free Webinar</h2>
                                <p className="pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor magna aliqua.</p>
                                <Link className="theme-btn mt-3" to="/"><span><BsLaptop/> Join Webinar</span></Link>
                                <div className="photo-frame"><img alt="" src={require('../../../assets/images/webnair.png')} /></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="dashboard-block join-facebook">
                                <div className="join-facebook-bar">
                                    <div className="join-facebook-bar-left">
                                        <h2>Join to Our Facebook Group</h2>
                                        <p>10000+ Members in Groups</p>
                                    </div>
                                    <div className="join-facebook-bar-right">
                                        <Link className="theme-btn" to="/"><span><BsFacebook/> Join Now</span></Link>
                                    </div>
                                </div>
                                <div className="facebook-img"><img alt="" src={require('../../../assets/images/facebook-group.png')} /></div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

           </div>
        </div>
          
        <Footer></Footer>

        <Modal className="VideoModal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            Close
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe src="https://www.youtube.com/embed/6I5BkyXYYQA" title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default Dashboard6;
import React, { useState } from "react";

import BackHeader from "../../Header/BackHeader";
import Footer from "../../Footer/Footer";

import{BiTrash} from "react-icons/bi"
import{BsCodeSlash, BsCardImage} from "react-icons/bs"
import{ AiOutlineClose} from "react-icons/ai"

import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

function EmbedViewMore() {

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return(
        <>
        <BackHeader></BackHeader>

        <div className="site-wrapper">
           <div className="site-container">
               <div className="embed-wrap">
                <div className="titleBar">
                        <div className="titleBar-profile">
                            <div className="titleBar-profile-img"><img alt="" src={require('../../../assets/images/staff-5.jpg')} /></div>
                            <div className="titleBar-left">
                                <h2>Johnson Doe’s Embeds</h2>
                                <p>Create and Use embeds to your site</p>
                            </div>
                        </div>
                        <div className="titleBar-right">
                        <Link to="" className="theme-btn sm-w-100"><span><BsCodeSlash/> Add Embed</span></Link>
                        </div>
                    </div>

                    <div className="embed-top">
                        <span>Embed Name</span>
                        <span>Website</span>
                        <span>Staff</span>
                        <span>Status</span>
                        <span>Action</span>
                    </div>

                    <div className="embed-list">

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                        <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                        <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>
                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>
                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>
                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>
                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

                <div className="embed-single">
                    <div className="embed-user">
                        <div className="embed-user-icon"><BsCardImage/></div>
                        <div className="embed-user-txt">
                            <h6>Test Embed Name</h6>
                            <p>15-09-2023 - 09:30 AM</p>
                        </div>
                    </div>
                    <div><span>app.aistaffs.co</span>m</div>
                    <div><span>Johnson Doe</span></div>
                    <div>
                       <div className="working-hours-left">
                            <div className="switch-btn">
                                <label htmlFor="" className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <Link to="/embed-code"><div className="embed-user-icon"><BsCodeSlash/></div></Link>
                    <Link onClick={handleShow2}><div className="embed-user-icon ms-2"><BiTrash/></div></Link>
                    </div>
                </div>

            </div>
               </div>
           </div>
        </div>
          
        <Footer></Footer>

        {/* Add Member Modal */}
       <Modal className="VideoModal sm-modal" show={show2} onHide={handleClose2} centered>
            <Modal.Body>
            <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose/></span>
             <div className="">
                <div className="text-center pt-4">
                  <div className="modalIcon"><BsCardImage/></div>

                  <div className="confirmation-cont">
                    <h5 className="pt-4">Are you sure to delete this “Johnson Doe - Embed AI Site” Embed ?</h5>
                    <p>You can’t Restore the deleted Embed data</p>
                  </div>

                  <div className="btnGroup mt-4 mb-4">
                    {/* <button className="theme-btn bdr" onClick={handleClose2}><span>Cancel</span></button> */}
                    <button className="theme-btn"><span><BiTrash/> Delete Embed</span></button>
                  </div>
                </div>
             </div>
            </Modal.Body>
        </Modal>
       {/* Add Member Modal */}

        </>
    )
}

export default EmbedViewMore;
import React, { useEffect, useState } from "react";
import CompanyHeader from "../../Header/CompanyHeader";
import Footer from "../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BsLaptop, BsFacebook } from "react-icons/bs"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import CheckResources from "../../CommonComponents/CheckResources";
import CompanyRows from "./CompanyRows";
import TitleBar from "../../CommonComponents/TitleBar";

const Company = () => {
    const dispatch = useDispatch()
    const company = useSelector(state => state.company)
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchCompany = () => {
        dispatch(fetchDataRedux("fetch-company-list", {}, "FETCH_COMPANY_LIST", loader, setLoader))
    }

    useEffect(() => {
        fetchCompany()
    }, [])

    return (
        <>
            <TitleBar title="Company" />
            <CompanyHeader />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="company-empty">

                        <div className="company-block">
                            <div className="company-block-title">
                                <div>
                                    <h3>Choose Company</h3>
                                    <p>Create and Manage Companies</p>
                                </div>
                                <Link to="/create-company" className="theme-btn" href=""><span><AiFillPlusCircle /> Create Company</span></Link>
                            </div>
                            {loader.fetch ?
                                <div className="create-box">
                                    <FaSpinner className="spin" size={25} />
                                </div>
                                :
                                company.list.length > 0 ?
                                    <div className="company-opt">
                                        <ul>
                                            {company.list.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <CompanyRows
                                                            curElem={curElem}
                                                            fetchCompany={fetchCompany}
                                                        />
                                                    </React.Fragment>

                                                )
                                            })}

                                            {/* <li>
                                                <div className="company-opt-left">
                                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/settings.png')} /></div>
                                                    <div className="company-opt-details">
                                                        <h6>Company Name</h6>
                                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                                    </div>
                                                </div>
                                                <div className="company-opt-right">
                                                    <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="company-opt-left">
                                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/manage.png')} /></div>
                                                    <div className="company-opt-details">
                                                        <h6>Company Name</h6>
                                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                                    </div>
                                                </div>
                                                <div className="company-opt-right">
                                                    <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="company-opt-left">
                                                    <div className="company-opt-img"><img alt="" src={require('../../../assets/images/intregration.png')} /></div>
                                                    <div className="company-opt-details">
                                                        <h6>Company Name</h6>
                                                        <p>Started at 10 Sep 2023 - 09:30AM</p>
                                                    </div>
                                                </div>
                                                <div className="company-opt-right">
                                                    <Link to="/departments" className="theme-btn bdr" href=""><span>Launch</span></Link>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                    :
                                    <div className="dashboard-block create-box">
                                        <h3>No Company Create, <br /> Create your company to Get Started !</h3>
                                        <img alt="" src={require('../../../assets/images/desk.png')} />
                                    </div>
                            }
                        </div>
                        <CheckResources />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Company;
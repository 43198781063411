import React, { useEffect, useState } from "react";
import BackHeader from "../../Header/BackHeader";
import Footer from "../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import { Link, useLocation } from "react-router-dom";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import ProjectRows from "./ProjectRows";
import { FaSpinner } from "react-icons/fa";

const Projects = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { cid, did } = queryString.parse(location.search)
    const project = useSelector(state => state.project)
    const [showProject, setShowProject] = useState([])
    const [filter, setFilter] = useState({
        q: "",
        status: { value: 'all', label: 'All' }
    })
    const [loader, setLoader] = useState({
        fetch: true
    })

    const options = [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'complete', label: 'Completed' }
    ]

    const fetchProjects = () => {
        let data = {
            cid, did
        }
        dispatch(fetchDataRedux("fetch-project-list", data, "FETCH_PROJECT_LIST", loader, setLoader))
    }

    const handleChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    useEffect(() => {
        if (filter.q === "" && filter.status === "all") {
            setShowProject(project.list)
        } else {
            let arr = project.list.filter((curElem) => {
                return curElem.name.toLowerCase().includes(filter.q.toLowerCase())
            }).filter((cur) => {
                return cur.projectStatus === filter.status.value || filter.status.value === "all"
            })
            setShowProject(arr)
        }
    }, [project.list, filter])

    useEffect(() => {
        if (cid && did) {
            fetchProjects()
        }
    }, [cid, did])

    return (
        <>
            <TitleBar title="Projects" />
            <BackHeader />

            <div className="site-wrapper">
                <div className="site-container">

                    <div className="outer-container">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>Projects</h2>
                                <p>Search Projects and work with Teams</p>
                            </div>
                            <div className="titleBar-right">
                                <div className="select-style alt">
                                    <Select
                                        options={options}
                                        value={filter.status}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        onChange={(e) => handleChange("status", e)}
                                    />
                                </div>
                                <div className="input-wrap">
                                    <div className="inp-outer icon-left">
                                        <input
                                            className="inp"
                                            type="text"
                                            name="q"
                                            placeholder="Search"
                                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        />
                                        <span className="inp-icon"><BiSearch /></span>
                                    </div>
                                </div>
                                <Link className="theme-btn sm-w-100" to={`/create-projects?cid=${cid}&did=${did}`}><span><AiFillPlusCircle /> Create Project</span></Link>
                            </div>
                        </div>
                    </div>

                    <div className="department-list">
                        <div className="row">
                            {showProject.length > 0 ?
                                showProject.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ProjectRows
                                                curElem={curElem}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <div className="col-12 text-center mt-4">
                                    {loader.fetch ?
                                        <FaSpinner className="spin" size={25} />
                                        : "You have no project created yet."}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Projects;
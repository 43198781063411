import React, { useEffect, useState } from "react";

import ChatHeader from "./ChatHeader/ChatHeader";
import { FiDownload } from "react-icons/fi"
import { AiOutlineClose } from "react-icons/ai"
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { fetchDataRedux, onFetchById } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import Messages from "./Messages/Messages";
import { defaultActiveConv } from "../../../Redux/Actions/ProjectActions";


const ChatScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id, pid, cid, did, type } = queryString.parse(location.search)
    const project = useSelector(state => state.project)

    const [loader, setLoader] = useState({
        fetch: true
    })
    const fetchProject = () => {
        let obj = {
            id: pid
        }
        dispatch(onFetchById("fetch-project", obj, "EDIT_PROJECT"))
    }

    useEffect(() => {
        if (pid) {
            fetchProject()
        }
    }, [pid])


    const fetchConversation = () => {
        let obj = {
            pid,
            cid,
            did,
            type: type === "s" ? "single" : "group"
        }
        dispatch(fetchDataRedux("fetch-conversation-list", obj, "FETCH_CONVERSATIONS_LIST", loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            dispatch(defaultActiveConv(id))
        }
    }, [id, project.conversationList])

    useEffect(() => {
        if (type) {
            fetchConversation()
        }
    }, [type])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <TitleBar title="Chat" />
                <ChatHeader
                    fetchConversation={fetchConversation}
                />
                <Messages
                    id={id}
                />


            </>
    )
}

export default ChatScreen;
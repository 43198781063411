import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onSubmitKd } from '../../../../../Redux/Actions/ProjectActions'
import UploadedRows from '../UploadedRows'

const WriteDoc = (props) => {
    const dispatch = useDispatch()
    const [uploadData, setUploadData] = useState([])
    const [data, setData] = useState({
        text: ""
    })
    const [loader, setLoader] = useState({
        submit: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submit: true
        })
        let obj = { ...props.ids }
        obj.data = data.text
        obj.type = "write"
        dispatch(onSubmitKd(props.url, obj, props.fetchData, setData, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    useEffect(() => {
        setUploadData(props.kdData.filter((curElem) => {
            return curElem.type === "write"
        }))
    }, [props.kdData])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="input-wrap">
                    <label htmlFor="">Content</label>
                    <div className="inp-outer">
                        <textarea
                            className="inp"
                            value={data.text}
                            rows={15}
                            name='text'
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="input-wrap">
                    <button type='submit' className="theme-btn full"><span>{loader.submit ? <>Updating <FaSpinner className='spin' /></> : "Update"}</span></button>
                </div>
            </form>

            <div className="input-wrap pt-5">
                <label className="p-0 mt-2" htmlFor="">Stored Documents</label>
                {uploadData.length > 0 ?
                    uploadData.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <UploadedRows
                                    curElem={curElem}
                                    fetchFunction={props.fetchData}
                                    type={props.type}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        You haven't written any doc yet!
                    </div>
                }
            </div>

        </>
    )
}

export default WriteDoc
import React from "react";

import { FiExternalLink } from "react-icons/fi"

const Footer = () => {

    return (
        <>
            <footer className="site-footer">
                <div className="site-container">
                    <div className="site-footer-main">
                        <div className="footer-left">
                            <p><span>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</span></p>
                            <p><span>© 2023, All Rights Reserved to</span> <a href="https://vineasx.com/">VineaSX Solutions LLC.</a> <span>Developed and Maintained by</span> <a href="http://www.vega6.com/">Vega6</a></p>
                        </div>
                        <div className="footer-right">
                            <ul>
                                <li><a target="_blank" href="https://aioffices.io/terms.html"><span><FiExternalLink /> Terms</span></a></li>
                                <li><a target="_blank" href="https://aioffices.io/privacy.html"><span><FiExternalLink /> Privacy</span></a></li>
                                <li><a target="_blank" href="http://support.vineasx.com/"><span><FiExternalLink /> Support</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsImages } from 'react-icons/bs'
import { HiDotsVertical } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const UserMessage = ({ curElem, activeConv }) => {
    const auth = useSelector(state => state.auth)
    const [assiData, setAssiData] = useState({
        name: ""
    })

    useEffect(() => {
        if (curElem && activeConv.staff.length > 0) {
            let staffData = activeConv.staff.find(({ id }) => +id === +curElem.staffId)
            if (staffData) {
                setAssiData({
                    ...assiData,
                    name: staffData.name
                })
            }
        }
    }, [curElem])

    return (
        <div className="message-wrap own-message">
            <div className="message-single">
                <div className="message-group">
                    <div className="user-photo"><img alt="" src={auth.user.profile} /></div>
                    <div className="message-head">
                        <span>{auth.user.name}</span>
                        <span>{curElem.created}</span>
                    </div>
                    <div className="message-body">
                        <div className="message-txt">
                            <p>{curElem.message}</p>
                        </div>
                        <div className="user-nav">
                            <Dropdown>
                                <Dropdown.Toggle variant="g" id="dropdown-basic">
                                    <div className="drop-icon"><HiDotsVertical /></div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <li><Link to="/">Action</Link></li>
                                        <li><Link to="/">Another action</Link></li>
                                        <li><Link to="/">Something else</Link></li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="msg-tags">Tags
                        <span>@{assiData.name}</span>
                        {/* <span><BsImages /> Image</span> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMessage
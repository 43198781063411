import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../Redux/Actions/AuthActions'
import { FaUserCircle, FaKey, FaShareAlt, FaUsersCog } from "react-icons/fa"
import { BiSupport, BiLogOut } from "react-icons/bi"
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom'

const UserNav = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const handleOut = () => {
        dispatch(logoutUser())
    }
    return (
        <div className="user-nav">
            <Dropdown>
                <Dropdown.Toggle variant="n" id="dropdown-basic">
                    <div className="user-img">
                        <div className="user-img-in"><img alt="" src={auth.user.profile} /></div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <ul>
                        <li><Link to="/my-account"><span><FaUserCircle /></span> My Account</Link></li>
                        <li><Link to="/privacy"><span><FaKey /></span> Privacy</Link></li>
                        <li><Link to="/integrations"><span><FaShareAlt /></span> Integrations</Link></li>
                        <li><Link to="/manage-team"><span><FaUsersCog /></span> Manage Team</Link></li>
                        <li><Link to="/upgrades"><span><FaUsersCog /></span>Upgrades</Link></li>
                        <li><Link to="/help-support"><span><BiSupport /></span> Help and Support</Link></li>
                        <li style={{ cursor: "pointer" }} onClick={handleOut}><a ><span><BiLogOut /></span> Logout</a></li>
                    </ul>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default UserNav
import React, { useEffect, useState } from 'react';
import { FaRegTrashCan, FaSpinner } from 'react-icons/fa6';
import { IoMicOutline, IoPlay } from 'react-icons/io5';
import { PiFileAudioDuotone } from 'react-icons/pi';
import { TiUser } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { fetchData, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { themeColor } from '../../../../Global/Global';
import VoiceCreateModal from './VoiceCreateModal';
import VoiceRows from './VoiceRows';

const CloneVoices = () => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [show, setShow] = useState(false)
    const [cloneVoices, setCloneVoices] = useState([])
    const [voice, setVoice] = useState([])

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [fileData, setFileData] = useState({
        url: "",
        type: "",
        localPath: ""
    })
    const [loader, setLoader] = useState({
        fetch: true,
        upload: false,
        record: false
    })

    const onCompleteLoad = (url, type, vv, localPath) => {
        setFileData({
            url: url,
            type: type,
            localPath
        })
    }

    const onInputFile = (e, type) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            let upFile = e.target.files[0]
            if (['audio/mpeg'].includes(upFile.type)) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onloadend = (e) => {
                    const ctx = new AudioContext();
                    const audioArrayBuffer = e.target.result;
                    ctx.decodeAudioData(audioArrayBuffer, data => {
                        // this is the success callback
                        const duration = data.duration;
                        if (+duration <= 30) {
                            if (upFile.size < allowedSize) {
                                const formData = new FormData()
                                formData.append('upload_type', type)
                                formData.append('file_type', "")
                                formData.append('file', upFile)
                                let fun = type === "recording" ? handleShow : false
                                dispatch(onUploadMedia(formData, onCompleteLoad, loader, setLoader, setPercent, type, fun))
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Max allowed size for Music File is 5MB!',
                                    confirmButtonColor: themeColor
                                })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Maximum allowed duration is 30 seconds to clone your voice!',
                                confirmButtonColor: themeColor
                            })
                        }
                    }, error => {
                        console.error(error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const fetchCloneVoices = () => {
        dispatch(fetchData("list-clone-voice", {}, setCloneVoices, loader, setLoader))
    }
    const fetchElevenlabsVoice = () => {
        dispatch(fetchData("fetch-elevenlabs-voices", {}, setVoice, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchCloneVoices()
        fetchElevenlabsVoice()
    }, [])

    return (
        <>
            <div className="titleBar">
                <div className="titleBar-left full">
                    <h2>Clone Your Own Voice</h2>
                    <p>Select a method you want to clone your voice</p>
                </div>
            </div>
            <div className="dashboard-block">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row">
                            <div className="file-field" style={{ background: "none" }}>
                                <div className="col-auto me-2">
                                    <div className="embed-user-icon fill">
                                        {loader.upload ? <FaSpinner className='spin' /> :
                                            <PiFileAudioDuotone />}</div>
                                </div>
                                <div className="col ps-0">
                                    {fileData.type === "voiceover" ?
                                        <>
                                            <h6>{fileData.url.slice(0, 40)}...</h6>
                                        </> :
                                        <>
                                            <h6 className="fw-700">Upload File</h6>
                                            <p>Click Here to Upload a Voice</p>
                                        </>
                                    }

                                </div>
                                {loader.upload ? null :
                                    <input
                                        type="file"
                                        onChange={(e) => onInputFile(e, "voiceover")}
                                        accept="audio/mpeg"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="theme-btn bdr" onClick={handleShow}>
                            <span>Create</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="or-style mt-5">
                <span>Or</span>
            </div>

            <div className="dashboard-block mt-5">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row">
                            <div className="col-auto">
                                <div className="embed-user-icon fill"><IoMicOutline /></div>
                            </div>
                            <div className="col ps-0">
                                <h6 className="fw-700">Record Your Voice</h6>
                                <p>Click Here to Record Your Voice</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="theme-btn bdr" onClick={handleShow}>
                            <span>Create</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="or-style mt-5">
                <span>Or</span>
            </div>

            <div className="dashboard-block mt-5">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="input-wrap mt-0">
                            {/* <label>Accent</label> */}
                            <div className="inp-outer">
                                <select
                                    className="inp"
                                    onChange={(e) => setFileData(JSON.parse(e.target.value))}
                                >
                                    <option value="">Select Voice</option>
                                    {voice.length > 0 ?
                                        voice.map((curElem, index) => {
                                            return (
                                                <option key={index} value={JSON.stringify({...curElem, type : "clone"})}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="theme-btn bdr" onClick={handleShow}>
                            <span>Create</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="embed-top mt-5">
                <span>Voice Name</span>
                <span>Voice Type</span>
                <span>Created At</span>
                <span style={{ opacity: 1 }}>Action</span>
            </div>

            <div className="embed-list">
                {cloneVoices.length > 0 ?
                    cloneVoices.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <VoiceRows
                                    curElem={curElem}
                                    type="clone"
                                    cloneVoices={cloneVoices}
                                    setCloneVoices={setCloneVoices}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        {loader.fetch ?
                            <FaSpinner className='spin' size={25} /> : "No voice found!"}
                    </div>
                }
            </div>

            <VoiceCreateModal
                show={show}
                handleClose={handleClose}
                fileData={fileData}
                setFileData={setFileData}
                fetchCloneVoices={fetchCloneVoices}
            />
        </>
    );
}

export default CloneVoices;

import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateCompany = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate('/company')
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onCreateCat = (data, fetchData, handlClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-category", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handlClose()
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


// No Axios Here

export const onChangeCompany = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_COMPANY", payload: { name, value } })
}

export const unmountCreateCompany = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_COMPANY" })
}


import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { HelpReducer } from "./HelpReducer";
import { SocialReducer } from "./SocialReducer";
import { ExtraReducer } from "./ExtraReducer";
import { CompanyReducer } from "./CompanyReducer";
import { DepartmentReducer } from "./DepartmentReducer";
import { ProjectReducer } from "./ProjectReducer";
import { StaffReducer } from "./StaffReducer";
import { DirectoryReducer } from "./DirectoryReducer";


const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    company: CompanyReducer,
    department: DepartmentReducer,
    directory: DirectoryReducer,
    project: ProjectReducer,
    staff: StaffReducer,
    rebrand: RebrandingReducer,
    help: HelpReducer,
    social: SocialReducer,
    extra: ExtraReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;
import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onSubmitKd } from '../../../../../Redux/Actions/ProjectActions'
import UploadedRows from '../UploadedRows'

const ImportDoc = (props) => {
    const dispatch = useDispatch()
    const [url, setUrl] = useState("")
    const [uploadData, setUploadData] = useState([])

    const [loader, setLoader] = useState({
        submit: false
    })

    const handleChange = (e, index) => {
        setUrl(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submit: true
        })
        let obj = { ...props.ids }
        obj.url = [{ url: url }]
        obj.type = "website"
        dispatch(onSubmitKd(props.url, obj, props.fetchData, setUrl ,loader, setLoader))
    }

    useEffect(() => {
        setUploadData(props.kdData.filter((curElem) => {
            return curElem.type === "website"
        }))
    }, [props.kdData])

    return (
        <>
            <div className="input-wrap mt-0">
                <label htmlFor="">Website URL</label>
                <form className="row" onSubmit={handleSubmit}>
                    <div className="col">
                        <div className="inp-outer">
                            <input
                                className="inp"
                                type="text"
                                placeholder="Type Document Title"
                                name="url"
                                value={url}
                                onChange={(e) => handleChange(e, 0)}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="theme-btn" type='submit'><span>{loader.submit ? <>Importing <FaSpinner className='spin' /></> : "Import"}</span></button>
                    </div>
                </form>
            </div>

            <div className="input-wrap pt-5">
                <label className="p-0" htmlFor="">Stored Website</label>

                {/* <div className="doc-list mt-4">
                    <div className="uploadBar" style={{ width: "40%" }}></div>
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="doc-icon"><FaFile /></div>
                                </div>
                                <div className="col">
                                    <h6>Design Ai Staff Document.docx</h6>
                                    <p>Uploading...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="icongroup">
                                <span><IoClose /></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                {uploadData.length > 0 ?
                    uploadData.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <UploadedRows
                                    curElem={curElem}
                                    fetchFunction={props.fetchData}
                                    type={props.type}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        You do not have a file imported yet!
                    </div>

                }

            </div>
        </>
    )
}

export default ImportDoc
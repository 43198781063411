import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi"
import { BsCheckLg } from "react-icons/bs"

import { RiCloseFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux";
import { onChangeCustomStaff, onCreateCustomStaff, onfetchCustomStatff, onHandleFolder } from "../../../Redux/Actions/StaffActions";
import { FaSpinner } from "react-icons/fa";
import Swal from "sweetalert2";
import { fetchData, fetchDataRedux, onUploadMedia } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import CreateDirectoryModal from "./Directory/CreateDirectoryModal";
import queryString from "query-string";

const CreateCustomAi = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const createCustom = useSelector(state => state.staff.createCustom)
    const directoryList = useSelector(state => state.directory.list)
    const [show, setShow] = useState(false)
    const [tone, setTone] = useState([])
    const [response, setResponse] = useState([])
    const [image, setImage] = useState("")
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        create: false,
        fetch: false
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleChange = (e) => {
        let { name, value } = e.target
        dispatch(onChangeCustomStaff(name, value))
    }

    const onCompeleteUpload = (url) => {
        setImage(url)
    }


    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const handleAdd = (isAdded, curElem) => {
        dispatch(onHandleFolder(isAdded, curElem))
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let obj = { ...createCustom }
        obj.image = image
        let url = "create-custom-staff"
        if (id) {
            url = "update-custom-staff"
        }
        dispatch(onCreateCustomStaff(url, obj, navigate, loader, setLoader))
    }


    const fetchTone = () => {
        dispatch(fetchData("fetch-tone", {}, setTone, false, false, false, "shortDataLg"))
    }

    const fetchResponse = () => {
        dispatch(fetchData("fetch-response-style", {}, setResponse, false, false, false, "shortDataLg"))
    }

    const fetchDirectoryList = () => {
        dispatch(fetchDataRedux("fetch-directory-list", {}, "FETCH_DIRECTORY_LIST", false, false, true))
    }

    const fetchCutomStaff = () => {
        let data = {
            id
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onfetchCustomStatff(data, loader, setLoader))
    }

    useEffect(() => {
        if (createCustom.id) {
            setImage(createCustom.image)
        }
    }, [createCustom.id])

    useEffect(() => {
        if (id) {
            fetchCutomStaff()
        }
    }, [id])


    useEffect(() => {
        fetchTone()
        fetchResponse()
        fetchDirectoryList()
    }, [])


    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <TitleBar title="YouAI" />
                <form onSubmit={handleSubmit}>
                    <header className="site-header">
                        <div className="site-container">
                            <div className="site-header-main">
                                <div className="backBtn alt">
                                    <Link className="back" to={`/youai`}><BiChevronLeft /></Link>
                                    <h6>{id ? "Update" : "Create"} YouAi Staff</h6>
                                </div>
                                <div className="header-right">
                                    {/* <Link className="theme-btn bdr"><span>Next</span></Link> */}
                                    {id ?
                                        <button className="theme-btn" type="submit"><span>{loader.create ? <>Updating <FaSpinner className="spin" /></> : "Update AI"}</span></button>
                                        :
                                        <button className="theme-btn" type="submit"><span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create AI"}</span></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="embed-progress">
                        <div className="embed-progress-in"></div>
                    </div>

                    <div className="site-wrapper">
                        <div className="site-container">
                            <div className="embed-wrap width-600">
                                <div className="titleBar">
                                    <div className="titleBar-left">
                                        <h2>Customize Your youAi</h2>
                                        <p>{id ? "Update" : "Create"} your youAi purpose</p>
                                    </div>
                                </div>

                                <div>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="b" className="navStyle">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    <h6>General</h6>
                                                    <p>Add Basic Info about AI</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    <h6>Knowledge</h6>
                                                    <p>Add Directory Info</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="pt-5">
                                            <Tab.Pane eventKey="first">
                                                <div className="input-wrap mt-0">
                                                    <label htmlFor="">YouAi Name</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp"
                                                            type="text"
                                                            placeholder="Type Ai Name"
                                                            onChange={handleChange}
                                                            name="name"
                                                            value={createCustom.name}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="input-wrap for-check">
                                                    <label htmlFor="">YouAi Gender</label>
                                                    <div className="inp-group">
                                                        <label className="custom-radio">
                                                            <span>Male Ai</span>
                                                            <input
                                                                type="radio"
                                                                name="gender"
                                                                onChange={handleChange}
                                                                value={"male"}
                                                                checked={createCustom.gender === "male"}
                                                                required
                                                            />
                                                            <span className="checkmark"><BsCheckLg /></span>
                                                        </label>
                                                        <label className="custom-radio">
                                                            <span>Female Ai</span>
                                                            <input
                                                                type="radio"
                                                                name="gender"
                                                                onChange={handleChange}
                                                                value={"female"}
                                                                checked={createCustom.gender === "female"}
                                                                required />
                                                            <span className="checkmark"><BsCheckLg /></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="input-wrap">
                                                    <label htmlFor="">YouAi Agent Role</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp"
                                                            type="text"
                                                            placeholder="Type Agent Role"
                                                            onChange={handleChange}
                                                            name="category"
                                                            value={createCustom.category}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="input-wrap">
                                                    <label htmlFor="">Role Description</label>
                                                    <div className="inp-outer">
                                                        <textarea
                                                            className="inp"
                                                            name="description"
                                                            placeholder="Write Role Description"
                                                            onChange={handleChange}
                                                            value={createCustom.description}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    {/* <div className="file-field">
                                                <div className="me-3"><img alt="" src={require('../../../assets/images/icon/img.png')} /></div>
                                                <div>
                                                    <h6>Click to Upload YouAi Image</h6>
                                                    <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                                </div>
                                                <input type="file" />
                                            </div> */}

                                                    <div className="file-field">
                                                        <div className="me-3 d-flex align-items-center justify-content-center" style={{ height: 50 }}>
                                                            {loader.upload ?
                                                                <FaSpinner className="spin" size={25} />
                                                                :
                                                                <img alt="" src={image ? image : require('../../../assets/images/icon/img.png')} style={{ maxHeight: "100%" }} />
                                                            }
                                                        </div>
                                                        <div>
                                                            <h6>Click to Upload YouAi Image</h6>
                                                            <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                                        </div>
                                                        {loader.upload ? null :
                                                            <input
                                                                type="file"
                                                                onChange={onInputImage}
                                                                accept="image/jpg, image/png, image/jpeg"
                                                            />
                                                        }
                                                    </div>

                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="input-wrap mt-0">
                                                    <label htmlFor="">Tone</label>
                                                    <div className="inp-outer">
                                                        <select
                                                            className="inp"
                                                            onChange={handleChange}
                                                            name="tone"
                                                            value={createCustom.tone}
                                                            required
                                                        >
                                                            <option value="">Select Tone</option>
                                                            {tone.length > 0 ?
                                                                tone.map((curElem, index) => {
                                                                    return (
                                                                        <option key={index} value={curElem.name}>{curElem.name}</option>
                                                                    )
                                                                })
                                                                : ""}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor=""> Style</label>
                                                    <div className="inp-outer">
                                                        <select
                                                            className="inp"
                                                            onChange={handleChange}
                                                            name="writingStyle"
                                                            value={createCustom.writingStyle}
                                                            required
                                                        >
                                                            <option value="">Select Response</option>
                                                            {response.length > 0 ?
                                                                response.map((curElem, index) => {
                                                                    return (
                                                                        <option key={index} value={curElem.name}>{curElem.name}</option>
                                                                    )
                                                                })
                                                                : ""}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Directory Folders</label>
                                                    <div className="dir-folder">
                                                        <ul>
                                                            {directoryList.length > 0 ?
                                                                directoryList.map((curElem, index) => {
                                                                    let isAdded = createCustom.directory.find(({ id }) => +id === +curElem.id)
                                                                    return (
                                                                        <li
                                                                            className={isAdded ? "active" : ""}
                                                                            style={{ cursor: "pointer" }}
                                                                            key={index}
                                                                            onClick={() => handleAdd(isAdded, curElem)}
                                                                        >
                                                                            <span>{curElem.name}</span>
                                                                            {isAdded ?
                                                                                <RiCloseFill /> : ""}
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""}
                                                        </ul>
                                                        <p className="mt-3 text-center"> Want to create a new directory?<span onClick={handleShow} style={{ cursor: "pointer", color: "#EF1564" }} > Click here</span></p>

                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div >
                </form>

                <CreateDirectoryModal
                    show={show}
                    handleClose={handleClose}
                />
                <Footer />

            </>
    )
}

export default CreateCustomAi;